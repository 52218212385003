import { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Box, Spinner } from "@twilio-paste/core";

import { getTokenFromTwilio } from "../services/api";
import { AppState, actionCreators } from "../store";
import AppContainer from "./AppContainer";
import Login from "./login/login";
import "./main.css";

function App(): ReactElement {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { login, logout } = bindActionCreators(actionCreators, dispatch);
  const token = useSelector((state: AppState) => state.token);

  const username = localStorage.getItem("username") ?? "";
  const password = localStorage.getItem("password") ?? "";
  const partner = localStorage.getItem("partner") ?? "";

  async function goToHome(setToken: (token: string) => void) {
    const name = localStorage.getItem("name") ?? "";

    try {
      const token = await getTokenFromTwilio(username.trim(), partner, name);
      if (token === "") {
        return "Algo deu errado. Tente novamente.";
      }
      setToken(token);

      return token;
    } catch (error) {
      return error as string;
    }
  }

  useEffect(() => {
    goToHome(setToken)
      .then((token) => {
        login(token);
      })
      .catch(async () => {
        localStorage.setItem("username", "");
        localStorage.setItem("password", "");
        logout();

        // unregister service workers
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
          registration.unregister();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const setToken = (token: string) => {
    login(token);
    setLoading(false);
  };

  if ((!token && !loading) || !username || !password) {
    return <Login setToken={setToken} />;
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        height="100%"
        width="100%"
      >
        <Spinner size="sizeIcon110" decorative={false} title="Loading" />
      </Box>
    );
  }

  return <AppContainer setToken={setToken} />;
}

export default App;
