import { useEffect, useState } from "react";

import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { twilioErrors } from "../../assets/twilioErrors";

import { MessageStatus } from "../../store/reducers/messageListReducer";

import MessageActions from "./MessageActions";
import Reactions, { ReactionsType } from "./Reactions";

type MessageStatuses = {
  [MessageStatus.Delivered]?: number;
  [MessageStatus.Read]?: number;
  [MessageStatus.Failed]?: number;
  [MessageStatus.Sending]?: number;
};

interface SingleMessageProps {
  getStatus: Promise<MessageStatuses>;
  author: string;
  topPadding: number;
  lastMessageBottomPadding: number;
  sameAuthorAsPrev: boolean;
  messageTime: string;
  onDeleteMessage: () => void;
  updateAttributes: (reactions: { reactions: ReactionsType }) => void;
  reactions?: ReactionsType;
  text: string;
  media: JSX.Element | null;
}

const statusStyle = {
  display: "inline-block",
  verticalAlign: "middle",
  marginLeft: "4px",
};

const MessageView: React.FC<SingleMessageProps> = (
  props: SingleMessageProps
) => {
  const theme = useTheme();
  const { text, getStatus, onDeleteMessage } = props;
  const [status, setStatus] = useState<MessageStatuses>({});

  useEffect(() => {
    getStatus.then((value) => setStatus(value));
  }, [getStatus]);

  return (
    <>
      {props.author === localStorage.getItem("username") ||
      !props.author.includes("whatsapp") ? (
        <>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              paddingTop: props.topPadding,
              paddingBottom: props.lastMessageBottomPadding,
            }}
          >
            {props.author == localStorage.getItem("username")
              ? "Eu"
              : props.sameAuthorAsPrev && props.author}

            <Box
              style={{
                backgroundColor: theme.backgroundColors.colorBackgroundPrimary,
                color: theme.backgroundColors.colorBackgroundBody,
                fontFamily: theme.fonts.fontFamilyText,
                fontSize: theme.fontSizes.fontSize30,
                fontWeight: theme.fontWeights.fontWeightNormal,
                lineHeight: theme.lineHeights.lineHeight30,
                paddingTop: theme.space.space40,
                paddingBottom: theme.space.space40,
                paddingLeft: theme.space.space30,
                paddingRight: theme.space.space30,
                borderRadius: theme.space.space30,
                maxWidth: "75%",
              }}
            >
              {props.media}
              <Box
                style={{
                  whiteSpace: "pre-wrap",
                  paddingTop: theme.space.space30,
                }}
              >
                {props.text}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexGrow: 10,
                  paddingTop: theme.space.space30,
                  justifyContent: "space-between",
                }}
              >
                <Box fontSize={theme.fontSizes.fontSize20}>
                  {props.messageTime}
                </Box>

                <Box
                  style={{
                    paddingLeft: theme.space.space30,
                    display: "flex",
                  }}
                >
                  {status[MessageStatus.Sending] ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="18px"
                        viewBox="0 0 24 18"
                        width="18px"
                        fill="#fff"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                      </svg>
                    </>
                  ) : null}

                  {status[MessageStatus.Failed] ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enable-background="new 0 0 24 24"
                        height="18px"
                        viewBox="0 0 24 18"
                        width="18px"
                        fill="#fff"
                      >
                        <g>
                          <rect fill="none" height="24" width="24" x="0" />
                        </g>
                        <g>
                          <g>
                            <g>
                              <path d="M21,10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-0.1c-2.73,2.71-2.73,7.08,0,9.79s7.15,2.71,9.88,0 C18.32,15.65,19,14.08,19,12.1h2c0,1.98-0.88,4.55-2.64,6.29c-3.51,3.48-9.21,3.48-12.72,0c-3.5-3.47-3.53-9.11-0.02-12.58 s9.14-3.47,12.65,0L21,3V10.12z M12.5,8v4.25l3.5,2.08l-0.72,1.21L11,13V8H12.5z" />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <span style={statusStyle}>
                        {status[MessageStatus.Failed]}
                      </span>
                    </>
                  ) : null}

                  {status[MessageStatus.Read] ? (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="18px"
                        viewBox="0 0 24 18"
                        width="18px"
                        fill="#25da2e"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
                      </svg>
                    </>
                  ) : null}

                  {status[MessageStatus.Delivered] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="18px"
                      viewBox="0 0 24 18"
                      width="18px"
                      fill="#fff"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
                    </svg>
                  ) : null}

                  <MessageActions
                    messageText={text}
                    onMessageDelete={onDeleteMessage}
                  />
                </Box>
              </Box>
            </Box>

            {status[MessageStatus.Failed] ? (
              <div style={{ textAlign: "right", paddingTop: "4px" }}>
                <span style={{ color: "#D61F1F" }}>
                  {
                    twilioErrors[
                      status[MessageStatus.Failed] as keyof typeof twilioErrors
                    ]
                  }
                </span>
              </div>
            ) : null}

            <Reactions
              reactions={props.reactions}
              onReactionsChanged={(reactions) =>
                props.updateAttributes({ reactions })
              }
            />
          </Box>
        </>
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            paddingTop: props.topPadding,
            paddingBottom: props.lastMessageBottomPadding,
          }}
        >
          <Box
            style={{
              color: theme.textColors.colorTextIcon,
              fontFamily: theme.fonts.fontFamilyText,
              fontSize: theme.fontSizes.fontSize30,
              fontWeight: theme.fontWeights.fontWeightNormal,
              lineHeight: theme.lineHeights.lineHeight30,
            }}
          >
            {props.sameAuthorAsPrev && props.author}
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              style={{
                backgroundColor: theme.backgroundColors.colorBackground,
                color: theme.textColors.colorText,
                fontFamily: theme.fonts.fontFamilyText,
                fontSize: theme.fontSizes.fontSize30,
                fontWeight: theme.fontWeights.fontWeightNormal,
                lineHeight: theme.lineHeights.lineHeight30,
                paddingTop: theme.space.space40,
                paddingBottom: theme.space.space40,
                paddingLeft: theme.space.space30,
                paddingRight: theme.space.space30,
                borderRadius: theme.space.space30,
              }}
            >
              {props.media}
              {props.text}
              <Box
                paddingTop="space30"
                fontSize={theme.fontSizes.fontSize20}
                color={theme.textColors.colorTextIcon}
              >
                {props.messageTime}
              </Box>
            </Box>
          </Box>

          <Reactions
            reactions={props.reactions}
            onReactionsChanged={(reactions) =>
              props.updateAttributes({ reactions })
            }
          />
        </Box>
      )}
    </>
  );
};

export default MessageView;
