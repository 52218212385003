export const twilioErrors = {
  63019: "Falha ao baixar mídia.",
  63025: "A mídia já existe.",
  63012: "O provedor de canal retornou um erro de serviço interno.",
  63021: "Erro de conteúdo inválido do canal.",
  63018: "Limite de taxa excedido para canal.",
  63024: "Destinatário de mensagem inválido.",
  63020: "Twilio encontrou um erro na conta do Business Manager.",
  63016:
    "Falha no envio de mensagem fora do limite, use um modelo de mensagem.",
  63022: "Certificado vname inválido.",
  63032:
    "Não podemos enviar esta mensagem a este usuário devido a uma limitação do WhatsApp.",
  63005: "O canal não aceitou determinado conteúdo.",
  63001: "O canal não conseguiu autenticar a solicitação.",
  63003: "O canal não conseguiu encontrar o endereço.",
  63033: "Destinatário bloqueado para receber mensagem.",
  63013: "Violação da política do canal.",
  63030: "Parâmetro não suportado para tipo de mensagem de canal.",
  63010:
    "A plataforma da Twilio encontrou um erro interno ao processar esta mensagem.",
  63028:
    "O número de parâmetros fornecidos não corresponde ao número esperado de parâmetros.",
  63027: "O modelo não existe para um idioma e localidade.",
  63029: "O receptor não conseguiu baixar o modelo.",
  63007:
    "Configuração de envio com falha, favor entrar em contato com o suporte.",
};
