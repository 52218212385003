import { Box } from "@twilio-paste/core";
import { ChevronDoubleLeftIcon } from "@twilio-paste/icons/esm/ChevronDoubleLeftIcon";
import { ChevronDoubleRightIcon } from "@twilio-paste/icons/esm/ChevronDoubleRightIcon";
import { Client, JSONObject } from "@twilio/conversations";
import React, { useState } from "react";

import styles from "../../styles";
import ConversationsList from "./ConversationsList";
import CreateConversationButton from "./CreateConversationButton";
import { useSelector } from "react-redux";
import { AppState } from "../../store";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { getSdkConversationObject } from "../../conversations-objects";

interface ConvosContainerProps {
  client?: Client;
}

const ConversationsContainer: React.FC<ConvosContainerProps> = (
  props: ConvosContainerProps
) => {
  const [listHidden, hideList] = useState(false);
  const conversations = useSelector((state: AppState) => state.convos);

  function sdkConvo(convo: ReduxConversation) {
    return getSdkConversationObject(convo);
  }

  function isConvoFinished(convo: ReduxConversation) {
    const SDKCONVO = sdkConvo(convo);
    const attributes = SDKCONVO.attributes as JSONObject | null;
    if (attributes?.finished) return true;

    return false;
  }

  const convosOpened = () => {
    let convosOpened = 0;
    conversations.forEach((convo) => {
      if (!isConvoFinished(convo)) {
        convosOpened++;
      }
    });
    return convosOpened;
  };

  return (
    <Box
      style={
        listHidden
          ? { ...styles.convosWrapper, ...styles.collapsedList }
          : styles.convosWrapper
      }
    >
      <Box style={styles.newConvoButton}>
        <CreateConversationButton
          client={props.client}
          collapsed={listHidden}
        />
        <p
          style={{
            top: "64.8px",
            left: "0",
            padding: "12px 16px 12px 12px",
            position: "absolute",
            zIndex: 7,
            width: "93.7%",
            textAlign: "center",
            background: "#F4F4F6",
          }}
        >
          Conversas em Aberto: <strong>{convosOpened()} </strong>de{" "}
          {conversations.length}
        </p>
      </Box>
      <Box style={styles.convoList} id="conversations-container">
        {!listHidden ? <ConversationsList /> : null}
      </Box>
      <Box style={styles.collapseButtonBox}>
        <Box
          paddingTop="space30"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onClick={() => hideList(!listHidden)}
        >
          {listHidden ? (
            <ChevronDoubleRightIcon decorative={false} title="Colapsar" />
          ) : (
            <ChevronDoubleLeftIcon decorative={false} title="Colapsar" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationsContainer;
