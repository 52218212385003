import { HelpText, Label } from "@twilio-paste/core";
import PhoneInput from "react-phone-input-2";
import "../../assets/style.css";
import styles from "../../styles";

type PhoneInputProps = {
  label: string;
  phone: string;
  setPhone: (phone: string, country: any) => void;
  onBlur?: (phone: string, country: any) => void;
  error?: string;
  helpText?: string;
  disabledField?: boolean;
};

export default function InputPhone({
  phone,
  label,
  setPhone,
  onBlur,
  error,
  helpText,
  disabledField,
}: PhoneInputProps) {
  return (
    <>
      <Label htmlFor="phone-input">
        <div style={styles.modalInputLabel}>{label}</div>
      </Label>
      <PhoneInput
        country={"br"}
        specialLabel=""
        autoFormat={false}
        value={phone}
        onChange={(phone, country) => setPhone(phone, country)}
        onBlur={(e, country) =>
          onBlur && onBlur(e.currentTarget.value, country)
        }
        disabled={disabledField}
        inputClass={error ? "invalid" : ""}
      />
      {error && (
        <HelpText id="error_help_text" variant="error">
          {error}
        </HelpText>
      )}
      {!error && helpText && (
        <HelpText id="error_help_text">{helpText}</HelpText>
      )}
    </>
  );
}
