export const templates = [
  {
    title: "GERAL - Abordagem inicial para atendimento",
    text: "Olá, {{1}}! Eu sou {{2}} do(a) {{3}}, e vou prosseguir com seu atendimento. Responda SIM para podermos iniciar, por favor.",
  },
  {
    title: "GERAL - Abordagem inicial para suporte",
    text: "Olá, {{1}}! Eu sou {{2}} do(a) {{3}}. Percebemos que você não conseguiu entrar na sala para {{4}} e gostaríamos de entender o que aconteceu. Responda SIM se puder conversar, por favor. Obrigado.",
  },
  {
    title: "GERAL - Template genérico",
    text: "Olá! Tudo bem? {{1}}\n\nEstamos entrando em contato {{2}}\n\nEquipe {{3}}.",
  },
  {
    title: "ACEITE TÉCNICO - Aceite de CPT com retificação",
    text: "Olá,\nSua proposta foi retificada com sucesso!\nPara concluir seu processo de análise é necessário que acesse seu e-mail cadastrado e valide as últimas alterações da sua proposta, após esse passo sua proposta seguirá para o setor de implantação.\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO - Aceite de CPT sem retificação",
    text: "Olá,\n\nPara concluir seu processo de análise é necessário que acesse seu e-mail cadastrado e valide as últimas alterações da sua proposta, após esse passo sua proposta seguirá para o setor de implantação.\n\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO -Terceiro contato BEM ADM",
    text: "Olá, tudo bem?\n\nAqui é da Bem Administradora novamente.\n\nEstamos tentando falar com você para fazer o preenchimento da sua declaração de saúde da sua proposta da Unimed Belo Horizonte. Este preenchimento é feito de forma totalmente online e dura em torno de 20 minutos. O objetivo deste preenchimento é garantir que a declaração seja preenchida corretamente.\n\nSei que você deve estar atarefado(a), mas por aqui podemos ir falando de acordo com a sua disponibilidade. Vamos agendar?\n\nÓtimo dia para você!",
  },
  {
    title: "ACEITE TÉCNICO -Agendamento com proposta finalizada",
    text: "Prezado cliente,\n\nNo momento, sua entrevista não se faz mais necessária, se identificarmos alguma pendência, faremos uma nova abordagem. Caso apresente dúvidas acerca da sua implantação, procure o seu corretor para esclarecimentos.",
  },
  {
    title: "ACEITE TÉCNICO - Confirmação de agendamento da entrevista",
    text: "Perfeito! Entrevista agendada {{1}} - {{2}} no horário de Brasília.\n\nMinutos antes da entrevista enviaremos por aqui o link da plataforma de acesso com o passo a passo.\n\nLembrando que todos os dependentes devem estar presentes no processo. (Caso a proposta não possua dependentes, desconsidere esta informação)\nTenha em mãos o CPF de todos.\n\nPedimos que priorize o uso do navegador Google Chrome para executar o passo a passo acima, pois é o navegador mais indicado para o processo. Caso não tenha esse navegador em seu aparelho, indicamos que baixe previamente a sua entrevista.\n\nO prazo de tolerância é de 5 minutos após horário agendado.\nTenham todos em mãos o CPF e um documento com a foto.\n\nHavendo qualquer imprevisto, pedimos que nos informe com antecedência para agendarmos um novo horário. Em caso de falta não justificada, sua proposta será devolvida ao corretor.\n\nÓtimo dia.",
  },
  {
    title:
      "ACEITE TÉCNICO -Confirmação de agendamento da entrevista - menor de idade",
    text: "Perfeito! Entrevista agendada {{1}} - {{2}} no horário de Brasília.\n\nMinutos antes da entrevista enviaremos por aqui o link da plataforma de acesso com o passo a passo.\n\nPedimos que priorize o uso do navegador Google Chrome para executar o passo a passo acima, pois é o navegador mais indicado para o processo. Caso não tenha esse navegador em seu aparelho, indicamos que baixe previamente a sua entrevista.\n\nLembrando que o menor de idade deve estar presente na entrevista em que seu responsável legal responderá pela sua declaração.\nTenha em mãos o CPF do mesmo e um documento com a foto do menor.\n\nO prazo de tolerância é de 5 minutos após horário agendado.\n\nHavendo qualquer imprevisto, pedimos que nos informe com antecedência para agendarmos um novo horário. Em caso de falta não justificada, sua proposta será devolvida ao corretor.\n\nÓtimo dia.",
  },
  {
    title: "ACEITE TÉCNICO - Confirmação de agendamento externo",
    text: "Olá, {{1}}! Sua entrevista com {{2}} foi agendada e confirmada para o dia {{3}} às {{4}}. Acesse o link {{5}} no dia e horário agendado para poder fazer sua entrevista ou caso você queira cancelar esse agendamento.",
  },
  {
    title: "ACEITE TÉCNICO - Dúvidas pré implantação",
    text: "A entrevista é uma etapa padronizada no processo de implantação da sua proposta.\nAtualmente esse processo é realizado por vídeo conferência como informado.\nEm caso de dúvidas, você pode estar entrando em contato com o seu corretor ou diretamente com a central de atendimento. {{1}}\nAguardamos seu retorno para prosseguir com o agendamento.",
  },
  {
    title: "ACEITE TÉCNICO - Agendamento pendente",
    text: "Olá, podemos agendar a entrevista pendente de {{1}}?\nLembrando que essa etapa é fundamental para prosseguir com a implantação da sua proposta.\nAguardo retorno.\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO - Link de atendimento Leve Saúde",
    text: "Olá, meu nome é {{1}} e falo em nome da {{2}}, segue o link de acesso para sua entrevista agendada às {{3}} do dia {{4}}.\nPeço que, por gentileza, só acesse o link minutos antes do horário agendado.\n\nLink de acesso: {{5}}\nPasso a passo de acesso:\nClique no link > permita sua câmera e microfone > Insira seu nome e verifique se sua câmera a e microfone estão funcionando conforme questionado na página aberta.\n\nNão é necessário baixar o app para acessar a sala, basta abrir o link em seu navegador mais atualizado (chrome, firefox, edge, safari)",
  },
  {
    title: "ACEITE TÉCNICO - Novo número - Prosseguir com agendamento",
    text: "Olá, falo em nome da {{1}}.\n\nPassando para informar que nosso canal para marcação da entrevista para {{2}} da declaração de saúde mudou, podemos seguir com o agendamento?",
  },
  {
    title: "ACEITE TÉCNICO - Novo número - Informação sobre envio do link",
    text: "Olá, falo em nome da {{1}}.\n\nPassando para informar que nosso canal para marcação da entrevista para {{2}} da declaração de saúde mudou. \nRessalto que o link será enviado por aqui, por email e sms.",
  },
  {
    title:
      "ACEITE TÉCNICO - Primeiro contato Atitude Saúde - Maiores de 50 anos",
    text: "Olá, tudo bem?\n\nEstou falando em nome da Atitude Saúde.\n\nEm primeiro lugar, gostaríamos de dar as boas-vindas. Sua empresa está em processo de contratação do nosso plano de saúde e para garantir que vamos cuidar de você da melhor forma, precisamos realizar o preenchimento de sua declaração de saúde. \nEste preenchimento será feito 100% online, através de uma videoconferência e tem duração média de 20 minutos. \nPodemos iniciar o agendamento do seu atendimento?",
  },
  {
    title: "ACEITE TÉCNICO - Primeiro contato - Revisão DS",
    text: "Olá, tudo bem? Meu nome é {{1}} e eu falo em nome da operadora {{2}}.\n\nRecebi a proposta de {{3}} para análise e agora o nosso próximo passo é agendar sua entrevista 100% online para revisão dos dados relatados na declaração de saúde.\n\nEsse processo é através de uma videoconferência e tem duração média de 20 minutos.\n\nRessaltamos que, se a proposta possuir dependentes menores de idade, estes devem estar presentes na entrevista, acompanhados do representante legal, e os demais dependentes não necessariamente no mesmo ambiente, pois o link pode ser compartilhado e acessado de mais de um dispositivo.\n\nAguardo seu retorno e qualquer dúvida, estou à disposição!",
  },
  {
    title: "ACEITE TÉCNICO - Primeiro contato UNIMED VR",
    text: "Olá, tudo bem?\n\nEstou falando em nome da operadora Unimed Volta Redonda.\n\nEm primeiro lugar, gostaríamos de dar novamente as boas-vindas e te parabenizar pelo seu novo plano.\n\nPara garantir que vamos cuidar de você da melhor forma, precisamos agendar uma revisão dos dados da sua declaração de saúde. Esta revisão será feita 100% online.\n\nEsta revisão será feita através de uma videoconferência e tem duração média de 20 minutos.\n\nRessaltamos que para esta revisão, TODOS os dependentes devem estar presentes, não necessariamente no mesmo ambiente pois as pessoas podem entrar de mais de um dispositivo com o mesmo link de acesso. Se um ou mais dependentes forem menores de idade, estes devem estar acompanhados do representante legal.\n\nPodemos iniciar o agendamento do seu atendimento?",
  },
  {
    title: "ACEITE TÉCNICO - Primeiro contato BEM ADM",
    text: "Olá, tudo bem?\n\nEstou falando em nome da Bem Administradora.\n\nEstou entrando em contato porque você demonstrou interesse em ter um plano da operadora Unimed Belo Honrizonte.\n\nPara continuar o processo de contratação, precisamos preencher a sua declaração de saúde e, caso a proposta que você preencheu possua, dos dependentes também. Nós vamos ajudar no processo de preenchimento da declaração para que você não tenha nenhuma dúvida.\n\nEste preenchimento será feito através de uma videoconferência e tem duração média de 20 minutos.\n\nRessaltamos que para este preenchimento, TODOS os dependentes devem estar presentes, não necessariamente no mesmo ambiente pois as pessoas podem entrar de mais de um dispositivo com o mesmo link de acesso. Se um ou mais dependentes forem menores de idade, estes devem estar acompanhados do representante legal.\n\nPodemos iniciar o agendamento do seu preenchimento?",
  },
  {
    title: "ACEITE TÉCNICO - Primeiro contato Klini Senior",
    text: "Olá, tudo bem? Meu nome é {{1}}, eu falo em nome da {{2}}.\n\nRecebi a proposta de {{3}} para análise e agora o nosso próximo passo é agendar sua entrevista 100% online para revisão dos dados relatados na declaração de saúde.\nCom a adesão do Plano Klini Sênior, nesse processo também será realizado uma Avaliação Global do Idoso a fim de traçar planos e metodologias de cuidado personalizadas aos clientes.\n\nEsse processo é através de uma videoconferência e tem duração média de {{4}} .\n\nRessaltamos que, se a proposta possuir dependentes menores de idade, estes devem estar presentes na entrevista, acompanhados do representante legal, e também os demais dependentes não necessariamente no mesmo ambiente, pois o link pode ser compartilhado e acessado de mais de um dispositivo.\n\nAguardo seu retorno e qualquer dúvida, estou à disposição!",
  },
  {
    title: "ACEITE TÉCNICO - Segundo contato UNIMED VR",
    text: "Olá, tudo bem? Sou eu da Unimed Volta Redonda.\n\nNão sei se você teve tempo de olhar a minha mensagem anterior, mas precisamos para garantir que temos todas as informações que precisamos para iniciar a cuidar de você realizar uma revisão dos dados da sua declaração de saúde que será feita 100% online e que dura em torno de 20 minutos.\n\nPodemos iniciar o agendamento do seu atendimento?\n\nAguardo retorno.\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO - Terceiro contato",
    text: "Olá\n\nPodemos agendar sua entrevista?\nSua proposta só poderá ser implantada após essa etapa.\nTemos o prazo de até hoje para a conclusão.\n\nAguardamos seu retorno para prosseguir.\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO - Terceito contato UNIMED VR",
    text: "Olá, tudo bem?\n\nAqui é da Unimed Volta Redonda novamente.\n\nEstamos tentando falar com você para fazer uma revisão dos dados da sua declaração de saúde. Esta revisão é feita de forma totalmente online e dura em torno de 20 minutos. O objetivo desta revisão é garantir que temos todas as informações que precisamos para iniciar a cuidar de você.\n\nSei que você deve estar atarefado(a), mas por aqui podemos ir falando de acordo com a sua disponibilidade. Vamos agendar?\n\nÓtimo dia para você!",
  },
  {
    title: "ACEITE TÉCNICO - Dúvidas pós implantação",
    text: "O atendimento é uma etapa padronizada no processo de liberação da carteirinha.\nAtualmente esse processo é realizado por vídeo conferência como informado.\nEm caso de dúvidas, você pode estar entrando em contato com o seu corretor ou diretamente com a central de atendimento. {{1}}\nAguardamos seu retorno para prosseguir com o agendamento.",
  },
  {
    title: "ACEITE TÉCNICO - Primeiro contato BEM ADM",
    text: "Olá, tudo bem?\n\nEstou falando em nome da Bem Administradora.\n\nEstou entrando em contato porque você demonstrou interesse em ter um plano da operadora {{1}}.\n\nPara continuar o processo de contratação, precisamos preencher a sua declaração de saúde e, caso a proposta que você preencheu possua, dos dependentes também. Nós vamos ajudar no processo de preenchimento da declaração para que você não tenha nenhuma dúvida.\n\nEste preenchimento será feito através de uma videoconferência e tem duração média de 20 minutos.\n\nRessaltamos que para este preenchimento, TODOS os dependentes devem estar presentes, não necessariamente no mesmo ambiente pois as pessoas podem entrar de mais de um dispositivo com o mesmo link de acesso. Se um ou mais dependentes forem menores de idade, estes devem estar acompanhados do representante legal.\n\nPodemos iniciar o agendamento do seu preenchimento?",
  },
  {
    title: "ACEITE TÉCNICO - Segundo contato BEM ADM",
    text: "Olá, tudo bem? Sou eu da Bem Administradora.\n\nNão sei se você teve tempo de olhar a minha mensagem anterior, mas para continuar o processo de contratação da sua proposta, precisamos realizar nossa entrevista. Esta entrevista é 100% online e dura em torno de 20 minutos.\n\nPodemos agendar?\n\nAguardo retorno.\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO - Terceiro contato BEM ADM",
    text: "Olá, tudo bem?\n\nAqui é da Bem Administradora novamente.\n\nEstamos tentando falar com você para fazer a entrevista qualificada para prosseguirmos com o processo de inclusão da sua proposta junto a operadora. Esta entrevista é feita de forma totalmente online e dura em torno de 20 minutos.\n\nSei que você deve estar atarefado(a), mas por aqui podemos ir falando de acordo com a sua disponibilidade. Vamos agendar?\n\nÓtimo dia para você!",
  },
  {
    title: "ACEITE TÉCNICO - Solicitação de cancelamento",
    text: "Tudo bem, vamos devolver sua proposta para o setor administrativo prosseguir. Em caso de dúvidas, entre em contato com a sua administradora.\nÓtimo dia!",
  },
  {
    title: "GERAL - Problemas técnicos para acessar link",
    text: "Olá, {{1}}, percebemos que você pode estar enfrentando problemas técnicos para acessar {{2}}.\n\nPara solucionar esse problema, baixe o aplicativo Medex Meets e insira o código {{3}} para acessar a videochamada.\n\niOS - https://apps.apple.com/br/app/medex-meets/id1636795943\nAndroid - https://play.google.com/store/apps/details?id=br.com.medex.medexmeets&pli=1",
  },
  {
    title: "GERAL - Instabilidade no sistema corrigida",
    text: "Oi {{1}}! Percebemos que você tentou entrar no nosso serviço de {{2}} e enfrentou problemas por uma instabilidade no sistema. Estamos enviando esta mensagem para dizer que você conseguirá entrar agora sem problemas. Esperamos você lá!.",
  },
  {
    title: "ACEITE TÉCNICO - Envio de link para início de atendimento",
    text: 'Olá, meu nome é {{1}} e vou prosseguir com o seu atendimento agendado hoje.\n\nLink de acesso: {{2}}\n\nPasso a passo de acesso:\nClique no link > permita sua câmera e microfone > Insira seu nome e verifique se sua câmera a e microfone estão funcionando conforme questionado na página aberta.\n\nNão é necessário baixar o app para acessar a sala, basta abrir o link em seu navegador mais atualizado (chrome, firefox, edge, safari)\n\nOu se preferir, pedimos que baixe o aplicativo """"Medex Meets"""", quando baixar clique em """"vamos lá"""" e faça o teste de áudio em """"gravar áudio"""", em seguida insira o código: {{3}} e o seu nome.\n\nO prazo de tolerância é de 5 minutos após horário agendado.\n\nQualquer dúvida, estou a disposição para te auxiliar!',
  },
  {
    title: "ACEITE TÉCNICO - Explicação de CPT - Com e-mail",
    text: "Olá! Gostaria de adicionar uma informação referente a sua Entrevista Qualificada realizada em {{1}}:\nExiste uma cláusula contratual chamada CPT que será aplicada em sua condição pré-existente: {{2}}; {{3}};\n\nEsta cláusula se trata de uma cobertura parcial temporária com prazo máximo de 2 anos aplicada as condições de saúde pré-existentes declaradas e identificadas durante a Entrevista Qualificada.\nDurante esse período não será possível realizar procedimentos de alta complexidade, cirurgias e leitos especiais relacionados as condições citadas acima.\n\nPara concluir seu processo de análise é necessário que acesse seu e-mail cadastrado e valide as últimas alterações da sua proposta, após esse passo sua proposta seguirá para o setor de implantação. \n\nPara maiores informações, consulte seu corretor.\n\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO - Explicação de CPT - Sem e-mail",
    text: "Olá! Gostaria de adicionar uma informação referente a sua Entrevista Qualificada realizada em {{1}}:\nExiste uma cláusula contratual chamada CPT que será aplicada em sua condição pré-existente: {{2}}; {{3}};\n\nEsta cláusula se trata de uma cobertura parcial temporária com prazo máximo de 2 anos aplicada as condições de saúde pré-existentes declaradas e identificadas durante a Entrevista Qualificada.\nDurante esse período não será possível realizar procedimentos de alta complexidade, cirurgias e leitos especiais relacionados as condições citadas acima.\n\nPara maiores informações, consulte seu corretor.\n\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO - Falta da entrevista - Com SLA",
    text: "Tempo de entrevista expirado! Pedimos que entre em contato o mais breve possível para agendar um novo horário.",
  },
  {
    title: "ACEITE TÉCNICO - Falta da entrevista - Sem SLA",
    text: "Tempo de entrevista expirado! O prazo para realização de entrevista encerrou e sua proposta foi devolvida.\nPedimos que entre em contato com o seu corretor para solicitar a reapresentação da sua proposta.",
  },
  {
    title: "ACEITE TÉCNICO - Horário não confirmado a mais de 2 horas",
    text: "Olá.\nNão recebemos a confirmação do horário acima e ele foi preenchido.\nPeço que retorne o contato o mais breve possível para agendarmos um novo de acordo com a sua disponibilidade.",
  },
  {
    title: "ACEITE TÉCNICO - Informações aoós a entrevista",
    text: "Olá. Após a realização da entrevista não temos mais acesso a sua proposta. Pedimos que entre em contato com o seu corretor para os próximos passos e andamento do plano. Obrigada!",
  },
  {
    title: "ACEITE TÉCNICO - Instruções para realização da entrevista",
    text: "Sua entrevista será realizada pela plataforma MEDEX MEETS, e o acesso se dará por um link que será enviado por aqui minutos antes da sua entrevista se iniciar, juntamente com o passo a passo.\nO link pode ser acessado por celular, tablet ou computador com webcam. Pedimos apenas que priorize o acesso pelos navegadores Google Chrome ou Safari.\nCaso prefira, temos disponível o aplicativo MEDEX MEETS disponível para Android e IOS, o acesso a entrevista pode ser também realizado por lá:\n\niOS - https://apps.apple.com/br/app/medex-meets/id1636795943\nAndroid - https://play.google.com/store/apps/details?id=br.com.medex.medexmeets&pli=1",
  },
  {
    title: "ACEITE TÉCNICO - Lembrete de agendamento da entrevista",
    text: "Olá, estamos passando para lembrar que você possui uma {{1}} agendada para {{2}} às {{3}}, no horário de Brasília.",
  },
  {
    title: "ACEITE TÉCNICO - Lembrete de retificação",
    text: 'Olá, será necessário que realize a inserção das condições pré-existentes declaradas durante a entrevista realizada no dia {{1}}, devido as mesmas não terem sido adicionadas em sua declaração de saúde preenchida previamente. \nDeste modo, solicitamos que acesse seu e-mail cadastrado na proposta e conclua as retificações em relação aos proponentes:\nBENEFICIÁRIO: {{2}}:\nInsira a condição de saúde pré-existente declarada:\n· Escreva: "{{3}}; {{4}}" na pergunta de n° {{5}};\n· Escreva: "{{6}}; {{7}} na pergunta de n° {{8}}.\n· Escreva "{{9}}, {{10}}" na pergunta de n° {{11}}.\nEssa etapa é fundamental para dar continuidade ao processo de implantação do seu plano.\nPeço que sinalize através deste mesmo canal, assim que realizar as retificações.',
  },
  {
    title: "ACEITE TÉCNICO - Menor sem documento",
    text: "Não tem problema, pedimos que apresente o documento do responsável legal pelo menor, que irá participar do processo respondendo por ele, no momento da entrevista.\n\nLembrando que ambos devem estar presentes no processo.\n\nQualquer dúvida, estamos à disposição.",
  },
  {
    title: "ACEITE TÉCNICO - Sem número de identificação para buscar proposta",
    text: "Olá.\nPara prosseguirmos com o atendimento nos informe o nome completo do titular da proposta ou o número da mesma.",
  },
  {
    title: "ACEITE TÉCNICO - Indisponibilidade do cliente dentro do SLA",
    text: "Entendemos a situação, porém como temos um prazo para a conclusão desta etapa, a proposta será devolvida.\nPedimos que entre em contato com o seu corretor para solicitar a reapresentação da mesma e assim que ela retornar, faremos um novo contato para seguir com o agendamento dentro do nosso prazo e sua disponibilidade.\n\nÓtimo dia.",
  },
  {
    title: "ACEITE TÉCNICO - Entrevista realizada apenas por vídeo conferência",
    text: "No momento o processo de entrevista é feito apenas por vídeo conferência.\nA plataforma utilizada é a Medex Meets para a segurança de todos.\nO acesso é feito via link direto que enviamos no dia e horário agendado ou através por código de acesso também disponibilizado, caso você prefira utilizar o aplicativo.\n\nAguardamos seu retorno para prosseguir.",
  },
  {
    title: "ACEITE TÉCNICO - Explicação da necessidade da presença do menor",
    text: "O menor deve estar presente junto com o seu responsável legal durante a entrevista pois é a norma da operadora.\nQual o melhor dia e horário para agendarmos com ambos presentes?",
  },
  {
    title: "ACEITE TÉCNICO - Primeiro contato - Preenchimento DS",
    text: "Olá, tudo bem? Meu nome é {{1}}, eu faço parte da central de atendimento da {{2}}. \nRecebi a proposta de {{3}} para análise e agora o nosso próximo passo é agendar sua entrevista 100% online para preenchimento da declaração de saúde e vou prosseguir com o seu atendimento.\nEsse processo é através de uma videoconferência e tem duração média de 20 minutos. \n\nRessaltamos que, se a proposta possuir dependentes menores de idade, estes devem estar presentes na entrevista, acompanhados do representante legal. Contendo dependentes maiores de idade, eles deverão participar da entrevista e responder as perguntas de forma individual. \n\nEssa etapa é fundamental para a implantação do contrato. (Não havendo dependentes em sua proposta, desconsidere essa informação) \n\nPodemos fazer o agendamento da entrevista?",
  },
  {
    title: "ACEITE TÉCNICO - Perguntas fora da área médica",
    text: "Olá, somos da área médica.\n\nPara demais informações entre em contato com o seu corretor e/ou central de atendimento.\nÓtimo dia.",
  },
  {
    title: "ACEITE TÉCNICO - Recusa para mostrar o menor",
    text: "Tudo bem, vamos devolver sua proposta para o setor administrativo e pedimos que entre em contato com o seu corretor para prosseguir.",
  },
  {
    title: "ACEITE TÉCNICO - Renotificação da retificação",
    text: 'Prezados,\nEnviamos uma renotificação da retificação, pois as condições não foram inseridas da forma como declaradas e solicitadas.\n\nEscreva: "{{1}}; {{2}}" na pergunta de n° {{3}};\nEscreva: "{{4}}; {{5}}; {{6}}" na pergunta de n° {{7}};\nEscreva "{{8}}, {{9}}" na pergunta de n° {{10}}.\n\nDesta forma, pedimos que realize a retificação de sua Declaração de Saúde, conforme orientado. Essa etapa é fundamental para dar continuidade ao processo de implantação do seu plano.',
  },
  {
    title: "ACEITE TÉCNICO - Retornar ao link após queda de conexão",
    text: "Olá! Percebemos que você perdeu a sua conexão momentaneamente. Para retornar à sua {{1}}, acesse esse Link: {{2}}. Você tem 5 minutos para retornar à chamada antes que ela seja encerrada por inatividade.",
  },
  {
    title: "ACEITE TÉCNICO - Segundo contato",
    text: "Olá, tudo bem?\nPodemos agendar sua entrevista?\nLembrando que essa etapa é fundamental para prosseguir com a implantação da sua proposta.\nAguardo retorno.\nÓtimo dia!",
  },
  {
    title: "ACEITE TÉCNICO - Sugestão de horário",
    text: "Olá meu nome é {{1}} do(a) {{2}}.Podemos agendar sua {{3}} para {{4}} às {{5}} ?",
  },
  {
    title: "ACEITE TÉCNICO - Aguardando na sala",
    text: "Olá, estou na sala aguardando, caso tenha dificuldade no acesso estou a disposição para te auxiliar!",
  },
  {
    title: "PHS - Consulta agendada enviada para o paciente",
    text: "Olá {{1}}, a sua consulta foi agendada para: {{2}}, {{3}}, às {{4}} com {{5}}. Através do link {{6}} você poderá entrar em sua consulta no dia e horário marcado e também poderá Cancelar caso precise. Lembrando que o tempo de tolerância para entrar na consulta é de 5 minutos.",
  },
  {
    title: "TMED - Envio de documentos",
    text: "Olá, tudo bem? Sou {{1}}, do(a) {{2}}. Responda SIM para receber os documentos gerados durante a sua {{3}} realizada no dia {{4}}, com {{5}}. Qualquer dúvida, estamos à disposição!",
  },
  {
    title: "TMED CONSULTA ELETIVA -",
    text: "Você acaba de ser chamado para sua consulta. Acesse o link {{1}} para ser atendido.",
  },
  {
    title: "TMED - Lembrete de agendamento de consulta",
    text: "Olá! Tudo bem? Estou passando para lembrar da sua consulta agendada para {{1}}. Podemos confirmar a sua presença?",
  },
  {
    title: "TMED CONSULTA ELETIVA - Faltou à consulta",
    text: "Olá! Tudo bem? Vimos que não compareceu a sua consulta com {{1}}, dia {{2}}. Vamos remarcar sua consulta? Estamos aqui para te ajudar a cuidar da sua saúde!",
  },
  {
    title: "TMED - Habilitar conversa",
    text: "Olá, {{1}}! Seja bem vindo à Telemedicina {{2}}. Gostaríamos de ativar esse canal para habilitar mensagens de texto na sua consulta. Responda SIM para habilitar.",
  },
  {
    title: "TMED - Envio de vídeo da consulta",
    text: "Prezado(a) {{1}},\n\nConforme sua solicitação segue o link para visualizar o vídeo da teleconsulta realizada no dia {{2}} às {{3}}:\n{{4}}\n\nEste link estará acessível até o dia {{5}} às {{6}}\nAtenciosamente,\n{{7}}",
  },
  {
    title: "WELLNESS - Boas -vindas Viva Mente",
    text: "Seja bem-vindo ao Programa Viva Mente!\n\nVamos juntos cuidar da sua saúde mental!?\nNosso programa tem duração de 3 meses e contempla: 2 consultas mensais com Suporte Emocional + 2 consultas/trimestre com Nutricionista + 2 consultas/trimestre com Personal Trainer\n\n- Horários de atendimento: de 9h às 18h de segunda à sexta.\n- Atendimentos por vídeo com os profissionais.\n- As consultas tem duração média de 25 minutos.\n\nNa primeira consulta te explicaremos melhor e você poderá tirar todas as suas dúvidas!\nQual melhor dia e horário para marcarmos sua consulta?",
  },
  {
    title: "WELLNESS - Boas -vindas Viva Mamãe",
    text: "Olá, mamãe! Tudo bem com você?\n\nVim contar uma novidade, de agora em diante, a consulta com a nossa nutricionista, será feita por teleconsulta!\n\nAssim, ficará mais fácil para você tirar suas dúvidas e receber as orientações de acordo com a sua necessidade!\nAproveite essa oportunidade para adequar a dieta e promover sua saúde e a do bebê!\n\nQual a melhor faixa de horário para a consulta?",
  },
  {
    title: "WELLNESS - Boas vindas PHS",
    text: " Seja bem-vindo a(ao) {{1}}\n\nVamos juntos cuidar da sua saúde!?\nNosso programa contempla: consultas mensais com {{2}}\n\nHorários de atendimento: de 9h às 18h de segunda à sexta. \nAtendimentos por vídeo\nQual melhor dia e horário para marcarmos sua consulta?",
  },
  {
    title: "WELLNESS - Aguardando agendamento 1 Viva Mente",
    text: "Você sabia que durante 3 meses pode ser atendido por nossa equipe?\n\nAproveite para marcar sua consulta com Suporte emocional, Nutricionista e Personal Trainer.\n\nQual melhor dia e horário para seu atendimento?",
  },
  {
    title: "WELLNESS - Aguardando agendamento 2 Viva Mente",
    text: "Vamos agendar sua consulta?\n\nEstamos aqui para te ajudar a cuidar da sua saúde!",
  },
  {
    title: "WELLNESS - Aguardando agendamento 3 Viva Mente",
    text: "Estamos aqui para te ajudar a cuidar da sua saúde!\n\n- Olá, deseja marcar suas consultas no programa Viva Mente?\n\n1- Sim\n2- Não\n\nCaso não queira receber mais mensagens como essa, nos informe.",
  },
  {
    title: "WELLNESS - Aguardando agendamento 4 Viva Mente",
    text: "Olá, deseja continuar com suas consultas no programa Viva Mente?\n\n1- Sim\n2- Não\n\nCaso não queira receber mais mensagens como essa, nos informe.",
  },
  {
    title: "WELLNESS - Aguardando na sala de atendimento",
    text: "Olá, estou na sala aguardando sua entrada.\n\nCaso apresente alguma dificuldade ou identifique algum erro no momento de acesso, nos sinalize, por gentileza.",
  },
  {
    title: "WELLNESS - Conclusão do programa",
    text: "Olá,\n\nSua jornada no programa Viva Mente está chegando ao fim.\n\nEsperamos que você tenha aplicado todas as dicas que recebeu, e que já tenha percebido como cuidar da saúde além de importante, pode também ser prazeroso.\n\nEstamos nos despedindo, mas queremos disponibilizar a você um número de contato. Caso precise, pode acionar a equipe de enfermeiras da corretora Brokers para auxiliar você em sua jornada de cuidados através do seu plano de saúde empresarial.\n\nCaso precise de ajuda para agendar consultas, ou exames, entre em contato através do número 21 98988-3932 pelo Whastapp.\n\nPor fim, gostaríamos de pedir que responda a pesquisa de satisfação do Programa Viva Mente clicando aqui: {{1}}\n\nObrigado!",
  },
  {
    title: "WELLNESS - Envio de link para atendimento",
    text: 'Olá, meu nome é {{1}} e vou prosseguir com a sua consulta agendada para hoje.\n\nLink de acesso: {{2}}\n\nPasso a passo do acesso:\n- Clique no link > Permita sua câmera e microfone > Insira seu nome e verifique se sua câmera a e microfone estão funcionando conforme questionado na página aberta.\n- Não é necessário baixar o app para acessar a sala, basta abrir o link em seu navegador mais atualizado (Chrome, Firefox, Edge, Safari).\n- Ou se preferir, baixe o aplicativo "Medex Meets. Após o Download clique em "Vamos lá" e faça o teste de áudio em "Gravar áudio".\n\nEm seguida insira o código: {{3}} e o seu nome.\n\nLembrando que temos uma tolerância de 10 minutos após o horário agendado.\nO acesso à sala estará disponível 15 minutos antes do horário marcado;\n\nCaso tenha um problema ou apresente alguma dificuldade, nos sinalize imediatamente. Estamos à disposição para te auxiliar!',
  },
  {
    title: "WELLNESS - Envio de plano",
    text: "Olá, tudo bem? O seu plano {{1}} foi finalizado e está pronto para ser enviado!\n\nEscolha uma opção abaixo:\n\n1. Receber meu plano;\n2. Falar com atendimento.",
  },
  {
    title: "WELLNESS - Lembrete de consulta Viva Mente",
    text: "Olá! Tudo bem?\n\nEstou passando para lembrar da sua próxima consulta com Suporte Emocional agendada para {{1}}, às {{2}}.\n\nVocê sabia que também tem direito a marcar consulta com Nutricionista e Personal Trainer? Caso tenha interesse, nos avise!",
  },
  {
    title: "WELLNESS - Agendamento realizado",
    text: "Perfeito! Agendamento realizado.\n\n{{1}}, às {{2}} (horário de Brasília), com {{3}}.\n\n- O link para acesso a sala de videoconferência será disponibilizado minutos antes da sua consulta;\n- O tempo de tolerância é de 10 minutos;\n- A consulta possui um tempo médio de 25 minutos;\n- Escolha um lugar reservado, livre de interrupções;\n\nCaso haja algum imprevisto, nos informe com antecedência para que possamos reagendar sua consulta.\n\nTenha um ótimo dia!",
  },
  {
    title: "WELLNESS - Mensagem de inativação Viva Mente",
    text: "Devido a falta de interação seu atendimento foi automaticamente finalizado, mas fique tranquilo, você pode nos chamar aqui sempre para agendarmos suas consultas. Até lá!",
  },
  {
    title: "WELLNESS - Pesquisa de satisfação Viva Mente",
    text: "Por fim, gostaríamos de pedir que responda a pesquisa de satisfação do Programa Viva Mente clicando aqui https://b24-sii7fv.bitrix24.site/crm_form_hxv16/\n\nAté a próxima!\nTime Viva Bem - Corretora Brokers/MDS",
  },
  {
    title: "WELLNESS - Pesquisa de satisfação Viva Mente com formulários",
    text: "Por fim, gostaríamos de pedir que responda a pesquisa de satisfação do Programa Viva Mente clicando aqui https://b24-sii7fv.bitrix24.site/crm_form_hxv16/\nAproveite para preencher os formulários para DASS e WHOQOL novamente para acompanharmos sua evolução e para avaliarmos os resultados do programa, por isso sua participação é fundamental.\n\nDASS: https://www.enfermeirabruna.com.br/brasifvivamente\nWhoqol - https://forms.gle/WxBpmxGxNwPtM4HX9\n\nAté a próxima!\nTime Viva Bem - Corretora Brokers/MDS",
  },
  {
    title: "WELLNESS - Consulta expirada",
    text: "Tempo de acesso à consulta expirado!\n\nSeu chamado será encaminhado ao setor de atendimento para que seja verificado a possibilidade do reagendamento de sua consulta.\n\nTenha um ótimo dia!",
  },
  {
    title: "WELLNESS - Link de atividades Viva Mente",
    text: "Olá, tudo bem?\nResponda OK para receber os links e atividades combinados na consulta Vivamente!\nObrigada",
  },
  {
    title: "WELLNESS - Aguardando agendamento 1",
    text: "Você sabia que todos os meses pode ser atendido por nossa equipe?\n\nAproveite para marcar sua consulta com Nutricionista, Personal Trainer e Suporte Emocional.\n\nQual melhor dia e horário para seu atendimento?",
  },
  {
    title: "WELLNESS - Aguardando agendamento 2",
    text: "Vamos agendar sua consulta?\n\nCom qual profissional gostaria de começar seus atendimentos?\n\n1 - Nutricionista\n2 - Personal Trainer\n3 - Suporte emocional\n\nEstamos aqui para te ajudar a cuidar da sua saúde!",
  },
  {
    title: "WELLNESS - Aguardando agendamento 3",
    text: "Podemos marcar nosso primeiro encontro?\n\nSalve esse contato na sua agenda e fique à vontade para entrar em contato conosco.",
  },
  {
    title: "WELLNESS - Aguardando agendamento 4",
    text: "Uma boa nutrição te devolve seu bem-estar e a sua saúde.\n\nO que acha de agendarmos sua consulta com o nosso nutricionista?",
  },
  {
    title: "WELLNESS - Aguardando agendamento 5",
    text: "A atividade física é um compromisso com você mesmo!\n\nO que acha de agendarmos sua consulta com o nosso personal trainer?",
  },
  {
    title: "WELLNESS - Aguardando agendamento 6",
    text: "Não deixe de cuidar da sua saúde. Agende suas consultas e continue em busca de hábitos saudáveis!\n\nQual é o melhor dia e o melhor horário para iniciarmos sua jornada de autocuidado?",
  },
  {
    title: "WELLNESS - Aguardando apróxima consulta 1",
    text: "Você sabia que já vai completar 1 mês da sua última consulta?\n\nVamos agendar sua próxima? Nos informe o turno de sua preferência.",
  },
  {
    title: "WELLNESS - Aguardando apróxima consulta 2",
    text: "Olá! Sabia que já fez um mês que você realizou sua consulta? Passou muito rápido!\n\nVamos marcar agora sua próxima?",
  },
  {
    title: "WELLNESS - Aguardando apróxima consulta 3",
    text: "Olá, tudo bem?! Vamos continuar juntos na missão de melhorar os hábitos?\n\nAgende sua consulta mensal!\n\nQual melhor dia e horário para você?",
  },
  {
    title: "WELLNESS - Aguardando apróxima consulta 4",
    text: "Apaixone-se por cuidar da sua saúde!\n\nFaz um tempo que não nos falamos. Posso te ajudar a marcar sua próxima consulta?",
  },
  {
    title: "WELLNESS - Aguardando apróxima consulta 5",
    text: "Hábitos saudáveis podem fortalecer sua imunidade!\n\nTer boa alimentação, praticar exercícios e cuidar da sua saúde mental.\n\nQual consulta você gostaria de marcar agora?",
  },
  {
    title: "WELLNESS - Aguardando apróxima consulta 6",
    text: "Pensamento positivo atrai boas vibrações e ajuda na saúde do corpo.\n\nEstamos aqui para te ajudar a cuidar da sua saúde!\n\nVamos agendar sua próxima consulta?\n\n1 - Sim\n2 - Não",
  },
  {
    title: "WELLNESS - Inativo Grupo 1 - Mensagem 1",
    text: "Nossa missão é incentivá-lo à praticar hábitos saudáveis e prevenir fatores de risco.\n\nQual consulta você gostaria de realizar primeiro?\n\nEscolha o número para fazer sua escolha:\n\n1. Nutricionista\n2. Personal Trainer\n3. Suporte Emocional",
  },
  {
    title: "WELLNESS - Inativo Grupo 1 - Mensagem 2",
    text: "Uma alimentação saudável é a base do bem-estar.\n\nVamos agendar sua consulta com a nutricionista?",
  },
  {
    title: "WELLNESS - Inativo Grupo 1 - Mensagem 3",
    text: "Oi! Estamos sentindo sua falta por aqui!\n\nQual é o melhor dia e o melhor horário para marcarmos sua primeira consulta?",
  },
  {
    title: "WELLNESS - Inativo Grupo 1 - Mensagem 4",
    text: "Sua saúde é preciosa! Vamos cuidar dela juntos!?\n\nVocê gostaria de agendar sua primeira consulta?\n\n1 - Sim\n2 - Não",
  },
  {
    title: "WELLNESS - Inativo Grupo 1 - Mensagem 6",
    text: "Você sabia que seu corpo ouve tudo que sua mente diz!?\n\nPodemos agendar seu horário com nossa equipe de suporte emocional!?\n\nAté lá!",
  },
  {
    title: "WELLNESS - Inativo Grupo 2 - Mensagem 7",
    text: "Sua opinião é muito importante para nós!\n\nCompartilhe conosco qual o principal motivo para não agendar suas consultas:\n\n1 - Não tenho interesse\n2 - Estou sem tempo\n3 - Desejo agendar futuramente",
  },
  {
    title: "WELLNESS - Lenbrete de consulta",
    text: "Olá! Tudo bem?\n\nEstou passando para lembrar da(s) sua(s) consulta(s) agendada(s) para {{1}} com {{2}}.\n\nPodemos confirmar a sua presença?",
  },
  {
    title: "WELLNESS - Mensagem para reagendar consulta que faltou",
    text: "Olá! Tudo bem?\n\nVimos que não compareceu a sua consulta com {{1}}, dia {{2}}.\n\nVamos remarcar sua consulta?\n\nLembrando que só pode ser realizado UM reagendamento por mês!\n\nEstamos aqui para te ajudar a cuidar da sua saúde!",
  },
  {
    title: "WELLNESS - Boas-vindas LIF Saúde",
    text: "Seja bem-vindo a LIF Saúde!\nVamos juntos cuidar da sua saúde!?\nNosso programa contempla: consultas mensais com Nutricionista + Personal Trainer + Suporte emocional.\n\n- Horários de atendimento: de 9h às 18h de segunda à sexta.\n- Atendimentos por vídeo com os profissionais.\n\nQual melhor dia e horário para marcarmos sua consulta?",
  },
  {
    title: "WELLNESS - Lembrete de consulta nutricionista Viva Mamãe",
    text: "Olá, mamãe! Tudo bem com você?\n\nPassando aqui para te lembrar de agendar a sua teleconsulta com a nossa nutricionista!\n\nFique atenta, pois a consulta só poderá ser realizada até o fim do mês!\n\nNão perca a oportunidade de adequar a dieta e melhorar seus hábitos alimentares!\n\nQual a melhor faixa de horário?",
  },
  {
    title: "WELLNESS - Novo número GRUPO SOLUM",
    text: "Olá, tudo bem! Estamos passando para avisar que esse é nosso novo número destinado ao Programa de Hábitos Saudáveis do GRUPO SOLUM.\n\nAtravés deste número você irá agendar suas consultas com Nutricionista, Profissional de Educação Física e Suporte Emocional; Irá receber seus planos de nutrição e de preparação física e; Poderá tirar todas as suas dúvidas.\n\nEstamos aqui para te ajudar a cuidar da sua saúde!",
  },
  {
    title: "WELLNESS - Novo número",
    text: "Olá, tudo bem! Estamos passando para avisar que esse é nosso novo número destinado ao {{1}}.\nAtravés deste número você irá agendar suas consultas com Nutricionista, Profissional de Educação Física e Suporte Emocional; Irá receber seus planos de nutrição e de preparação física e; Poderá tirar todas as suas dúvidas. \nEstamos aqui para te ajudar a cuidar da sua saúde!",
  },
  {
    title: "WELLNESS - Solicitação de outras especialidades Viva Leve",
    text: "Olá, tudo bem? Este canal de atendimento destina-se ao monitoramento de Hábitos Saudáveis do programa Viva Leve da Brokers!\n\nCaso precise de ajuda para agendar consultas, ou exames, entre em contato através do número: (21) 98988-3932 pelo Whatsapp.\n\nTenha um ótimo dia! ",
  },
  {
    title: "WELLNESS -  Número não localizado na base Viva Leve",
    text: "Olá, tudo bem? Este canal é destinado apenas ao monitoramento e agendamento de consultas do Programa Viva Leve.\n\nInfelizmente não localizamos o seu cadastro em nosso sistema.\nPara prosseguir é necessário que entre em contato com o setor responsável, através do Whatsapp 21 98988-3932. \n\nAgradecemos a compreensão.\nTenha um ótimo dia! ",
  },
  {
    title: "AÇÃO WELLNESS - Solicitação de dados de pacientes",
    text: "Olá, sou {{1}} da {{2}}. \nFalo com {{3}}?\nO motivo meu contato é pq a senhora/senhor participou da ação social do(a) {{4}} e preciso de algumas informações completares. \nMe confirma seu nome completo e CPF, por favor.\n-Peso e altura\n-HGT\n-Pressão arterial\nO senhor/senhora possuiu alguma comorbidade?\nEssas informações são para controle da ação, agradeço sua participação, obrigada!",
  },
  {
    title: "GERAL - Reagendamento casos específicos",
    text: "Olá, tudo bem?\n\nEstamos entrando em contato, pois devido {{1}}, teremos que reagendar sua consulta agendada para {{2}}, às {{3}}.\nDesde já, pedimos sinceras desculpas pelo ocorrido e lamentamos o transtorno causado.\nEstamos à sua inteira disposição para agendarmos um novo horário.\n\nNos informe o turno de sua preferência, por gentileza:",
  },
];
