import { Button } from "@twilio-paste/button";
import { ModalBody } from "@twilio-paste/core";
import {
  Modal,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
} from "@twilio-paste/modal";
import React from "react";

type ActionErrorModalProps = {
  isOpened: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  actionTxt?: string;
  error?: {
    code: number;
    message: string;
  };
  errorText: {
    title: string;
    description: string;
  };
};

const ActionErrorModal: React.FC<ActionErrorModalProps> = ({
  errorText,
  isOpened,
  actionTxt,
  onClose,
  onConfirm,
  error = {},
}) => (
  <Modal
    ariaLabelledby="name-change-error"
    isOpen={isOpened}
    size="default"
    onDismiss={onClose}
  >
    <ModalHeader>
      <ModalHeading as="h3">{errorText.title}</ModalHeading>
    </ModalHeader>
    <ModalBody>
      <div>{errorText.description}</div>
    </ModalBody>
    <ModalFooter>
      <ModalFooterActions>
        <Button variant="primary" onClick={onConfirm ? onConfirm : onClose}>
          {actionTxt ? actionTxt : "Fechar"}
        </Button>
      </ModalFooterActions>
    </ModalFooter>
  </Modal>
);

export default ActionErrorModal;
