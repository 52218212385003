import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/core";
import { HelpText } from "@twilio-paste/help-text";
import { useState } from "react";

import { getToken, getTokenFromTwilio } from "../../services/api";
import styles from "../../styles";
import { InputType } from "../../types";
import TwilioLogo from "../icons/MedexLogo";
import ModalInputField from "../modals/ModalInputField";
import { set } from "lodash";

type SetTokenType = (token: string) => void;

interface LoginProps {
  setToken: SetTokenType;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const [isFormDirty, setFormDirty] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(1);
  const [partner, setPartner] = useState("");
  const [userToken, setUserToken] = useState("");
  const [partners, setPartners] = useState("".split(",") ?? []);

  async function login(username: string, password: string): Promise<string> {
    try {
      await getToken(username.trim(), password);

      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
      const partners = localStorage.getItem("partners")?.split(",") ?? [];
      setPartners(partners);
      setFormError("");
      setStep(2);
      return "";
    } catch (error) {
      return error as string;
    }
  }
  async function goToHome(setToken: (token: string) => void) {
    const name = localStorage.getItem("name") ?? "";
    localStorage.setItem("partner", partner);

    try {
      const token = await getTokenFromTwilio(username.trim(), partner, name);
      if (token === "") {
        return "Algo deu errado. Tente novamente.";
      }
      setUserToken(token);
      setToken(token);
      setFormError("");

      return "";
    } catch (error) {
      return error as string;
    }
  }

  return (
    <Box style={styles.loginContainer}>
      <Box style={styles.loginContent}>
        <Box>
          <TwilioLogo />
        </Box>
        <div style={styles.loginTitle}>Medex Conversas</div>
        <div style={styles.subTitle}></div>
        {step === 1 ? (
          <Box style={styles.loginForm}>
            <Box style={styles.userInput}>
              <ModalInputField
                label="Nome de usuário"
                placeholder=""
                isFocused={true}
                error={
                  isFormDirty && !username.trim()
                    ? "Insira um nome de usuário para entrar."
                    : ""
                }
                input={username}
                onBlur={() => setFormDirty(true)}
                onChange={setUsername}
                id="username"
              />
            </Box>
            <Box style={styles.passwordInput}>
              <ModalInputField
                label="Senha"
                placeholder=""
                error={
                  isFormDirty && !password
                    ? "Insira uma senha para entrar."
                    : formError ?? ""
                }
                input={password}
                onChange={setPassword}
                inputType={showPassword ? InputType.Text : InputType.Password}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
                id="password"
              />
            </Box>
            <Box style={styles.loginButton}>
              <Button
                fullWidth
                disabled={!username || !password}
                variant="primary"
                onClick={async () => {
                  const error = await login(username, password);
                  if (error) {
                    setFormError(error);
                    setTimeout(() => {
                      setFormError("");
                    }, 3000);
                  }
                }}
                id="login"
              >
                Entrar
              </Button>
            </Box>
          </Box>
        ) : (
          <Box style={styles.loginForm}>
            <Box style={styles.userInput}>
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Selecione o parceiro
              </label>
              <select
                id="numproxies"
                onChange={(a) => setPartner(a.currentTarget.value)}
                value={partner}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="">Selecione</option>
                {partners?.map((p, index) => (
                  <option value={p} key={index}>
                    {p}
                  </option>
                ))}
              </select>
              {formError && (
                <HelpText id="error_help_text" variant="error">
                  {formError}
                </HelpText>
              )}
            </Box>
            <Box style={styles.loginButton}>
              <Button
                fullWidth
                disabled={!partner}
                variant="primary"
                onClick={async () => {
                  const error = await goToHome(props.setToken);
                  if (error) {
                    setFormError(error);
                    setTimeout(() => {
                      setFormError("");
                    }, 3000);
                  }
                }}
                id="partner-login"
              >
                Enviar
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box style={styles.loginBackground}>
        <Box
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#06033a",
            transform: "skewY(-12deg)",
            transformOrigin: "top right",
          }}
        />
      </Box>
    </Box>
  );
};

export default Login;
