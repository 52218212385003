import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { useMemo, useState } from "react";
import { templates } from "../../assets/templates";

type TemplateListProps = {
  setMessage(t: string): void;
  disabled?: boolean;
};

const TemplateList: React.FC<TemplateListProps> = (
  props: TemplateListProps
) => {
  const [showList, setShowList] = useState(false);

  const [showSettingVariables, setShowSettingVariables] = useState(false);

  const [template, setTemplate] = useState("");

  const [numInputs, setNumInputs] = useState(0);

  const [filteredTemplates, setFilteredTemplates] = useState(templates);

  const countPlaceholders = (str: string) => {
    setNumInputs((str.match(/\{\{.*?\}\}/g) || []).length);
  };

  const handleSubmit = () => {
    const updatedTemplate = replaceValues(template);
    props.setMessage(updatedTemplate);
    setShowSettingVariables(false);
  };

  const replaceValues = (res: string) => {
    const inputs = document.querySelectorAll(
      ".input-substitute"
    ) as NodeListOf<HTMLInputElement>;
    inputs.forEach((input) => {
      const id = input.getAttribute("id");
      const idNum = parseInt(id?.replace("input-", "") || "");
      const value = input.value;
      res = res.replace(`{{${idNum + 1}}}`, value || "");
    });
    return res;
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value;

    const filtered = templates.filter((t) => {
      return t.title.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredTemplates(filtered);
  };
  const replacePlaceholders = (str: string) => {
    const inputIds = Array.from({ length: numInputs }, (_, i) => `input-${i}`);
    const replaced = str.replace(/\{\{(.*?)\}\}/g, () => {
      const id = inputIds.shift();
      return `<input id="${id}" type="text" class="input-substitute bg-gray-300" style="margin: 4px" />`;
    });
    return <div dangerouslySetInnerHTML={{ __html: replaced }} />;
  };

  const list = useMemo(() => {
    return filteredTemplates.map((t, index) => (
      <button
        key={index}
        onClick={() => {
          setTemplate(t.text);
          countPlaceholders(t.text);
          setShowSettingVariables(true);
          setShowList((a) => !a);
        }}
        className="mt-2 w-full border-blue-900 hover:border-b-[.5px] text-left pb-2"
      >
        <TemplateItem {...t} />
      </button>
    ));
  }, [filteredTemplates]);

  return (
    <>
      {showList ? (
        <div
          className="absolute bottom-28 right-8 rounded-xl bg-gray-100 shadow-2xl  w-[450px] p-4"
          style={{ maxHeight: "60vh", overflowY: "auto" }}
        >
          <div className="flex justify-between">
            <h2 className=" text-lg font-bold mb-4">Selecione um template:</h2>
            <button
              onClick={() => setShowList((a) => !a)}
              className=" rounded-full bg-gray-300 shadow-2xl h-[20px] w-[20px] flex items-center justify-center"
            >
              <XMarkIcon className="h-4 w-4 text-gray-900" />
            </button>
          </div>
          <input
            type="text"
            id="search-input"
            autoComplete="false"
            autoSave="false"
            placeholder="Pesquise pelo título do template"
            style={{
              border: "1px solid #8891AA",
              padding: "8px 12px",
              height: "36px",
              margin: "0px",
              marginBottom: "12px",
              borderRadius: "4px",
              width: "100%",
            }}
            onChange={(e) => handleSearch(e as any)}
          />
          {list}
        </div>
      ) : (
        <button
          onClick={() => setShowList((a) => !a)}
          disabled={props.disabled}
          style={{
            zIndex: 10,
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            bottom: "65px",
            right: "30px",
          }}
          className="absolute bottom-28 right-8 rounded-full bg-blue-600 shadow-2xl h-[42px] w-[42px] flex items-center justify-center"
        >
          <PlusIcon className="h-6 w-6 text-white" />
        </button>
      )}
      {showSettingVariables && (
        <div className="absolute bottom-28 right-8 rounded-xl bg-gray-100 shadow-2xl  w-[650px] p-4">
          <div className="flex justify-between">
            <h2 className=" text-lg font-bold mb-4">
              Digite as variáveis do template
            </h2>
            <button
              onClick={() => setShowSettingVariables((a) => !a)}
              className=" rounded-full bg-gray-300 shadow-2xl h-[20px] w-[20px] flex items-center justify-center"
            >
              <XMarkIcon className="h-4 w-4 text-gray-900" />
            </button>
          </div>
          {replacePlaceholders(template)}

          <div className="flex justify-end">
            <button
              onClick={() => handleSubmit()}
              className="flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 mt-2"
            >
              Salvar
            </button>
          </div>
        </div>
      )}
    </>
  );
};
type TemplateProps = { title: string; text: string };
const TemplateItem: React.FC<TemplateProps> = ({ title }) => {
  return <div className="text-gray-900 font-medium">{title}</div>;
};
export default TemplateList;
