import { Button } from "@twilio-paste/button";
import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  CONVERSATION_MESSAGES,
  ERROR_MODAL_MESSAGES,
  WHATSAPP_PREFIX,
} from "../../constants";
import { getSdkConversationObject } from "../../conversations-objects";
import { addParticipant, getUsersFromTwilio } from "../../services/api";

import {
  successNotification,
  unexpectedErrorNotification,
} from "../../helpers";
import { AppState, actionCreators } from "../../store";

import React, { createRef, useMemo, useState } from "react";
import AddChatParticipantModal from "../modals/addChatMemberModal";

import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import Settings from "../settings/Settings";

interface ConversationDetailsProps {
  convoSid: string;
  participants: ReduxParticipant[];
  convo: ReduxConversation;
}

const ConversationDetails: React.FC<ConversationDetailsProps> = (
  props: ConversationDetailsProps
) => {
  const [isAddChatOpen, setIsAddChatOpen] = useState(false);
  const messages = useSelector((state: AppState) => state.messages);
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [nameProxy, setNameProxy] = useState("");
  const [errorProxy, setErrorProxy] = useState("");

  const [showError, setErrorToShow] = useState<
    | {
        title: string;
        description: string;
      }
    | false
  >();
  const [errorData, setErrorData] = useState<
    | {
        message: string;
        code: number;
      }
    | undefined
  >();
  const nameInputRef = createRef<HTMLInputElement>();

  const handleChatOpen = () => setIsAddChatOpen(true);
  const handleChatClose = () => setIsAddChatOpen(false);
  const theme = useTheme();

  const dispatch = useDispatch();
  const { updateCurrentConversation, updateConversation, addNotifications } =
    bindActionCreators(actionCreators, dispatch);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );
  const participants =
    useSelector((state: AppState) => state.participants)[props.convo.sid] ?? [];
  const leaveConvo = async () => {
    try {
      await sdkConvo.leave();
      successNotification({
        message: CONVERSATION_MESSAGES.LEFT,
        addNotifications,
      });
      updateCurrentConversation("");
    } catch {
      unexpectedErrorNotification(addNotifications);
    }
  };

  const checkOwner = () => {
    const username = localStorage.getItem("username") ?? "";

    const attributes =
      typeof sdkConvo.attributes === "object" ? sdkConvo.attributes : null;
    return (
      attributes !== null &&
      typeof attributes === "object" &&
      (attributes as Record<string, any>).isOwner?.includes(username)
    );
  };

  const checkIamOwner = () => {
    const username = localStorage.getItem("username") ?? "";
    const attributes =
      typeof sdkConvo.attributes === "object" ? sdkConvo.attributes : null;

    return (
      attributes !== null &&
      typeof attributes === "object" &&
      !(attributes as Record<string, any>).isOwner?.includes(username)
    );
  };

  // const checkHasOwner = () => {
  //   const attributes =
  //     typeof sdkConvo.attributes === "object" ? sdkConvo.attributes : null;

  //   return (
  //     attributes !== null &&
  //     typeof attributes === "object" &&
  //     (attributes as Record<string, any>).isOwner?.length
  //   );
  // };

  const fetchUsers = async (partner: string) => {
    try {
      const users = await getUsersFromTwilio(partner);
      return users as any;
    } catch (e) {
      setErrorData(e as any);
      setErrorToShow(ERROR_MODAL_MESSAGES.SEARCH_USERS);
    }
  };

  const atribbuteConvo = async (username: string) => {
    const partner = localStorage.getItem("partner");
    const loggedInUser = localStorage.getItem("username") ?? "";
    const usersAdmin: string[] = [];

    const users = await fetchUsers(partner as string);

    let attributes =
      typeof sdkConvo?.attributes === "string"
        ? JSON.parse(sdkConvo?.attributes)
        : null;
    users.forEach((user: any) => {
      if (user.username === username) {
        usersAdmin.push(user.username);
      } else if (user.twilioAdmin) usersAdmin.push(user.username);
    });
    attributes = {
      isOwner: usersAdmin,
      lastMessage: attributes?.lastMessage ?? "",
    };
    sdkConvo
      ?.updateAttributes(attributes)
      .then((convo) => {
        updateConversation(convo.sid, convo);
        successNotification({
          message:
            username == loggedInUser
              ? CONVERSATION_MESSAGES.ATTRIBUTED_TO_ME
              : CONVERSATION_MESSAGES.ATTRIBUTED,
          addNotifications,
        });
        focusBtn();
      })
      .catch((e) => {
        setErrorData(e);
        setErrorToShow(
          username == loggedInUser
            ? ERROR_MODAL_MESSAGES.ATTRIBUTE_TO_ME
            : ERROR_MODAL_MESSAGES.ATTRIBUTE_TO_OTHER
        );
      });
  };

  // const username = localStorage.getItem("username") ?? "";
  // const system = "conversation@medex.net.br";
  // const allowedUsers = [username, system];

  // participants.forEach(async (participant) => {
  //   if (
  //     participant.identity &&
  //     !allowedUsers.includes(participant.identity)
  //   ) {
  //     await removeParticipant(
  //       sdkConvo,
  //       getSdkParticipantObject(participant),
  //       addNotifications
  //     );
  //   }
  // });
  const focusBtn = () => {
    const btn = document.getElementById("transfer-btn");
    btn?.focus();
  };
  const disattibuteConvo = () => {
    let attributes =
      typeof sdkConvo.attributes === "object" ? sdkConvo.attributes : null;
    attributes = {
      isOwner: [],
      finished: false,
      lastMessage: "",
    };

    sdkConvo
      .updateAttributes(attributes)
      .then((convo) => {
        updateConversation(convo.sid, convo);
        successNotification({
          message: CONVERSATION_MESSAGES.DISATTRIBUTE,
          addNotifications,
        });
      })
      .catch((e) => {
        setErrorData(e);
        setErrorToShow(ERROR_MODAL_MESSAGES.DISATTRIBUTE);
      });
  };

  function emptyData() {
    setName("");
    setNameProxy("");
    setError("");
    setErrorProxy("");
  }

  function setErrors(errorText: string) {
    setError(errorText);
    setErrorProxy(errorText);
  }

  return (
    <Box
      style={{
        minHeight: 65,
        maxHeight: 65,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColors.colorBorderWeak,
      }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "45%",
            alignSelf: "center",
          }}
          color="colorText"
          fontFamily="fontFamilyText"
          fontSize="fontSize40"
          lineHeight="lineHeight60"
          fontWeight="fontWeightBold"
          maxHeight="100%"
        >
          {props.convo.friendlyName ?? props.convo.sid}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 20,
              minWidth: "156px",
            }}
          >
            <Button
              fullWidth
              id="transfer-btn"
              variant="primary"
              onClick={handleChatOpen}
              disabled={checkIamOwner()}
            >
              Transferir conversa
            </Button>
          </Box>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 20,
              minWidth: "120px",
            }}
          >
            {!checkOwner() ? (
              <Button
                fullWidth
                variant="primary"
                onClick={() =>
                  atribbuteConvo(localStorage.getItem("username") ?? "")
                }
                // disabled={checkHasOwner()}
              >
                Atribuir á mim
              </Button>
            ) : (
              <Button
                fullWidth
                variant="primary"
                onClick={() => disattibuteConvo()}
              >
                Desatribuir
              </Button>
            )}
          </Box>

          <Box
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
            color="colorTextWeak"
            fontFamily="fontFamilyText"
            fontSize="fontSize30"
            lineHeight="lineHeight40"
            fontWeight="fontWeightNormal"
            paddingRight="space60"
          >
            {`${props.participants.length}`}
            {props.participants.length > 1 ? " participantes" : " participante"}
          </Box>
          <Settings convo={props.convo} participants={props.participants} />
        </Box>
      </Box>
      {isAddChatOpen && (
        <AddChatParticipantModal
          name={name}
          isModalOpen={isAddChatOpen}
          title="Transferir Conversa"
          setName={(name: string) => {
            setName(name);
            setErrors("");
          }}
          error={error}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleChatClose();
          }}
          onBack={() => {
            emptyData();
            handleChatClose();
          }}
          action={async () => {
            const partnerPhone = localStorage.getItem("partnerPhone") ?? "";

            try {
              atribbuteConvo(name);
              await addParticipant(
                name,
                WHATSAPP_PREFIX + partnerPhone?.replace("+", ""),
                true,
                sdkConvo,
                addNotifications
              );
              emptyData();
              handleChatClose();
            } catch (e) {
              setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
              setErrorData(e as any);
            }
          }}
        />
      )}
    </Box>
  );
};

export default ConversationDetails;
