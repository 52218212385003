import { Box, ModalBody } from "@twilio-paste/core";
import { RefObject } from "react";
import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
import ModalInputField from "./ModalInputField";
import AddParticipantFooter from "./addParticipantFooter";

interface AddWhatsAppParticipantModalProps {
  name: string;
  isModalOpen: boolean;
  title: string;
  disabledInput?: boolean;
  proxyName: string;
  setName: (name: string) => void;
  setProxyName: (name: string) => void;
  error: string;
  errorProxy: string;
  nameInputRef: RefObject<HTMLInputElement>;
  onBack: () => void;
  action: () => void;
  handleClose: () => void;
}

const AddWhatsAppParticipantModal: React.FC<AddWhatsAppParticipantModalProps> =
  (props: AddWhatsAppParticipantModalProps) => {
    const checkNumber = (number: string) => {
      if (number.includes("55")) {
        if (number.length >= 12) return false;
        else return true;
      }
      return false;
    };

    return (
      <>
        <ConvoModal
          handleClose={() => props.handleClose()}
          isModalOpen={props.isModalOpen}
          title={props.title}
          modalBody={
            <ModalBody>
              <h3>Adicionar participante Whatsapp</h3>
              <Box as="form">
                <ModalInputField
                  isFocused={false}
                  label="Celular"
                  input={props.name}
                  disabledField={props.disabledInput}
                  placeholder="123456789012"
                  onChange={props.setName}
                  error={props.error}
                  help_text="O número de telefone do WhatsApp do participante."
                  prefixType="WhatsApp"
                />
                {/* <ModalInputField
                  label="Número do parceiro selecionado"
                  disabledField={true}
                  input={
                    props.proxyName + " " + localStorage.getItem("partner")
                  }
                  placeholder=" "
                  onChange={props.setProxyName}
                  error={props.errorProxy}
                  help_text="O número de telefone do Parceiro cadastrado na Twilio."
                  prefixType="WhatsApp"
                /> */}
                {/* <label className="block mb-2 text-sm font-medium text-gray-900">
                  Proxy Twilio
                </label>
                <select
                  id="numproxies"
                  onChange={(a) => props.setProxyName(a.currentTarget.value)}
                  value={props.proxyName}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option selected>Selecione</option>
                  <option value="551150397349">AVUS</option>
                  <option value="552140400826">Medex Healthtech Brasil</option>
                </select> */}
              </Box>
            </ModalBody>
          }
          modalFooter={
            <AddParticipantFooter
              isSaveDisabled={
                !props.name.trim() ||
                !props.proxyName.trim() ||
                !!props.error ||
                checkNumber(props.name)
              }
              actionName={ActionName.Save}
              onBack={() => {
                props.onBack();
              }}
              action={props.action}
            />
          }
        />
      </>
    );
  };

export default AddWhatsAppParticipantModal;
