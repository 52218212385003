import { Box } from "@twilio-paste/core";
import { KeyboardEventHandler, useLayoutEffect, useState } from "react";
// import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import MessageFile from "./MessageFile";
import TemplateList from "./TemplatesList";

interface MessageInputProps {
  message: string;
  disabled: boolean;
  disabledTemplate: boolean;
  onChange: (message: string, isTemplate: boolean) => void;
  onKeyPress: KeyboardEventHandler<HTMLTextAreaElement>;
  onFileRemove: (file: string) => void;
  assets: File[];
  participants: unknown[];
}

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function getTextWidth(text: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (context !== null && context !== undefined) {
    context.font = "14px Inter";
    return context.measureText(text).width;
  }
  return 0;
}

const MessageInput: React.FC<MessageInputProps> = (
  props: MessageInputProps
) => {
  const [cursorPosition, setCursorPostions] = useState<number>(0);
  const width = useWindowSize();
  //500 is the width of the rest of the components. So totalWidth-500=widthOfInput
  return (
    <Box>
      <TemplateList
        setMessage={(t) => {
          props.onChange(t, true);
        }}
        disabled={props.disabledTemplate}
      />
      <textarea
        onChange={(e) => {
          setCursorPostions(e.currentTarget.selectionStart ?? 0);
          props.onChange(e.currentTarget.value, props.disabled);
        }}
        aria-describedby="message_help_text"
        id="message-input-shorter"
        name="message-input-shorter"
        value={props.message}
        autoFocus
        disabled={props.disabled}
        autoComplete="false"
        autoSave="false"
        placeholder={
          props.disabled
            ? "Atribua a conversa a você para poder enviar mensagens"
            : "Adicione uma mensagem"
        }
        style={{
          border: props.assets.length ? "none" : "1px solid #8891AA",
          padding: "8px 12px",
          margin: `${"0 6px " + (props.assets.length ? "12" : "4") + "px 6px"}`,
          borderRadius: "4px",
          width: "100%",
          height: "auto",
          minHeight: "36px",
          maxHeight: "300px",
          resize: "vertical",
        }}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(cursorPosition, cursorPosition)
        }
        onKeyPress={props.onKeyPress}
      />

      {props.assets.length ? (
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {props.assets.map(({ name, size }) => (
            <MessageFile
              key={`${name + "_" + size}`}
              media={{ filename: name, size }}
              onRemove={() => props.onFileRemove(name + "_" + size)}
            />
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default MessageInput;
