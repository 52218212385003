import React, { createRef, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { Box, Spinner } from "@twilio-paste/core";
import { Client } from "@twilio/conversations";
import { AppState, actionCreators } from "../../store";

import {
  CONVERSATION_MESSAGES,
  ERROR_MODAL_MESSAGES,
  SMS_PREFIX,
  WHATSAPP_PREFIX,
} from "../../constants";
import {
  getSdkConversationObject,
  getSdkParticipantObject,
} from "../../conversations-objects";

import {
  successNotification,
  unexpectedErrorNotification,
} from "../../helpers";
import {
  addParticipant,
  disableConversation,
  removeParticipant,
} from "../../services/api";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { Content } from "../../types";
import ActionErrorModal from "../modals/ActionErrorModal";
import AddChatParticipantModal from "../modals/addChatMemberModal";
import AddSMSParticipantModal from "../modals/addSMSParticipantModal";
import AddWhatsAppParticipantModal from "../modals/addWhatsAppParticipant";
import ManageParticipantsModal from "../modals/manageParticipantsModal";
import SettingsMenu from "./SettingsMenu";
import { ReduxMessage } from "../../store/reducers/messageListReducer";

interface SettingsProps {
  participants: ReduxParticipant[];
  client?: Client;
  convo: ReduxConversation;
}

const Settings: React.FC<SettingsProps> = (props: SettingsProps) => {
  const [isManageParticipantOpen, setIsManageParticipantOpen] = useState(false);
  const handleParticipantClose = () => setIsManageParticipantOpen(false);
  const messages = useSelector((state: AppState) => state.messages);
  const [isAddChatOpen, setIsAddChatOpen] = useState(false);
  // TODO: move to app loading state
  const [isLoading, setLoading] = useState(false);
  const handleChatOpen = () => setIsAddChatOpen(true);
  const handleChatClose = () => setIsAddChatOpen(false);

  const [isAddSMSOpen, setIsAddSMSOpen] = useState(false);
  const handleSMSOpen = () => setIsAddSMSOpen(true);
  const handleSMSClose = () => setIsAddSMSOpen(false);

  const [isAddWhatsAppOpen, setIsAddWhatsAppOpen] = useState(false);
  const handleWhatsAppOpen = () => setIsAddWhatsAppOpen(true);
  const handleWhatsAppClose = () => setIsAddWhatsAppOpen(false);

  const [number, setNumber] = useState("55");

  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const [nameProxy, setNameProxy] = useState("");
  const [errorProxy, setErrorProxy] = useState("");

  const [showError, setErrorToShow] = useState<
    | {
        title: string;
        description: string;
      }
    | false
  >();
  const [showConfirm, setShowConfirm] = useState<
    | {
        title: string;
        description: string;
      }
    | false
  >();
  const [errorData, setErrorData] = useState<
    | {
        message: string;
        code: number;
      }
    | undefined
  >();
  const [confirmData, setConfirmData] = useState<
    | {
        message: string;
        code: number;
      }
    | undefined
  >();

  const nameInputRef = createRef<HTMLInputElement>();

  const dispatch = useDispatch();
  const { updateCurrentConversation, updateConversation, addNotifications } =
    bindActionCreators(actionCreators, dispatch);

  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.convo),
    [props.convo.sid]
  );

  function getLastMessage(messages: ReduxMessage[]) {
    return messages[messages.length - 1]?.body || "";
  }
  const participants =
    useSelector((state: AppState) => state.participants)[props.convo.sid] ?? [];

  const leaveConvo = async () => {
    try {
      await sdkConvo.leave();
      successNotification({
        message: CONVERSATION_MESSAGES.LEFT,
        addNotifications,
      });
      updateCurrentConversation("");
    } catch {
      unexpectedErrorNotification(addNotifications);
    }
  };

  const atribbuteConvo = async () => {
    const username = localStorage.getItem("username") ?? "";
    let attributes =
      typeof sdkConvo.attributes === "string"
        ? JSON.parse(sdkConvo.attributes)
        : null;

    if (attributes !== null && attributes.isOwner.length) return;
    else {
      attributes = {
        isOwner: [username],
        lastMessage: attributes?.lastMessage ?? "",
      };
    }
    sdkConvo
      .updateAttributes(attributes)
      .then((convo) => {
        updateConversation(convo.sid, convo);
        successNotification({
          message: CONVERSATION_MESSAGES.NAME_CHANGED,
          addNotifications,
        });
      })
      .catch((e) => {
        setErrorData(e);
        setErrorToShow(ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME);
      });

    // converter o json sdkConvo.attributes para objeto

    // const username = localStorage.getItem("username") ?? "";
    // const system = "conversation@medex.net.br";
    // const allowedUsers = [username, system];

    // participants.forEach(async (participant) => {
    //   if (
    //     participant.identity &&
    //     !allowedUsers.includes(participant.identity)
    //   ) {
    //     await removeParticipant(
    //       sdkConvo,
    //       getSdkParticipantObject(participant),
    //       addNotifications
    //     );
    //   }
    // });
  };

  const finishConvo = async () => {
    const partner = localStorage.getItem("partner");
    updateCurrentConversation("");

    try {
      await disableConversation(
        props.convo.sid,
        partner as string,
        addNotifications
      );
    } catch {
      unexpectedErrorNotification(addNotifications);
    }
  };

  const checkIamOwner = () => {
    const username = localStorage.getItem("username") ?? "";
    const attributes =
      typeof sdkConvo.attributes === "object" ? sdkConvo.attributes : null;

    return (
      attributes !== null &&
      typeof attributes === "object" &&
      !(attributes as Record<string, any>).isOwner?.includes(username)
    );
  };

  const getTheOwner = () => {
    const attributes =
      typeof sdkConvo.attributes === "object" ? sdkConvo.attributes : null;

    return (
      attributes !== null &&
      typeof attributes === "object" &&
      (attributes as Record<string, any>).isOwner
    );
  };

  const disattibuteConvo = () => {
    let attributes =
      typeof sdkConvo.attributes === "string"
        ? JSON.parse(sdkConvo.attributes)
        : null;
    attributes = {
      isOwner: [],
      lastMessage: getLastMessage(messages[sdkConvo.sid]),
    };

    sdkConvo
      .updateAttributes(attributes)
      .then((convo) => {
        updateConversation(convo.sid, convo);
        successNotification({
          message: CONVERSATION_MESSAGES.DISATTRIBUTE,
          addNotifications,
        });
      })
      .catch((e) => {
        setErrorData(e);
        setErrorToShow(ERROR_MODAL_MESSAGES.DISATTRIBUTE);
      });
    // const system = "conversation@medex.net.br";
    // try {
    //   await addParticipant(system, nameProxy, true, sdkConvo, addNotifications);
    //   leaveConvo();
    //   emptyData();
    // } catch (e) {
    //   setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
    //   setErrorData(e as any);
    // }
  };

  function emptyData() {
    setName("");
    setNameProxy("");
    setError("");
    setErrorProxy("");
  }

  function setErrors(errorText: string) {
    setError(errorText);
    setErrorProxy(errorText);
  }

  return (
    <>
      <SettingsMenu
        onParticipantListOpen={() => setIsManageParticipantOpen(true)}
        leaveConvo={disattibuteConvo}
        endProposal={() => {
          setShowConfirm(ERROR_MODAL_MESSAGES.CONFIRM_FINISH_CONVO);
          setConfirmData(undefined);
        }}
        disabled={checkIamOwner()}
        attribute={getTheOwner()}
        updateConvo={(val: string) =>
          sdkConvo
            .updateFriendlyName(val)
            .then((convo) => {
              updateConversation(convo.sid, convo);
              successNotification({
                message: CONVERSATION_MESSAGES.NAME_CHANGED,
                addNotifications,
              });
            })
            .catch((e) => {
              setErrorData(e);
              setErrorToShow(ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME);
            })
        }
        atribbuteConvo={atribbuteConvo}
        conversation={props.convo}
        addNotifications={addNotifications}
      />
      <ActionErrorModal
        errorText={showError || ERROR_MODAL_MESSAGES.CONFIRM_FINISH_CONVO}
        isOpened={!!showError}
        onClose={() => {
          setErrorToShow(false);
          setErrorData(undefined);
        }}
        error={errorData}
      />
      <ActionErrorModal
        errorText={showConfirm || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME}
        isOpened={!!showConfirm}
        onClose={() => {
          setShowConfirm(false);
          setConfirmData(undefined);
        }}
        actionTxt="Confirmar finalização"
        onConfirm={finishConvo}
        error={confirmData}
      />
      {isManageParticipantOpen && (
        <ManageParticipantsModal
          handleClose={handleParticipantClose}
          isModalOpen={isManageParticipantOpen}
          title="Gerenciar Participantes"
          participantsCount={props.participants.length}
          participantsList={props.participants}
          onClick={(content: Content) => {
            handleParticipantClose();
            switch (content) {
              case Content.AddSMS:
                handleSMSOpen();
                return null;
              case Content.AddWhatsApp:
                handleWhatsAppOpen();
                return null;
              case Content.AddChat:
                handleChatOpen();
                return null;
              default:
                return null;
            }
          }}
          onParticipantRemove={async (participant) => {
            await removeParticipant(
              sdkConvo,
              getSdkParticipantObject(participant),
              addNotifications
            );
          }}
        />
      )}
      {isAddSMSOpen && (
        <AddSMSParticipantModal
          name={name}
          proxyName={nameProxy}
          isModalOpen={isAddSMSOpen}
          title="Gerenciar Participantes"
          setName={(name: string) => {
            setName(name);
            setErrors("");
          }}
          setProxyName={(name: string) => {
            setNameProxy(name);
            setErrors("");
          }}
          error={error}
          errorProxy={errorProxy}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleSMSClose();
          }}
          onBack={() => {
            emptyData();
            handleSMSClose();
            setIsManageParticipantOpen(true);
          }}
          action={async () => {
            try {
              await addParticipant(
                SMS_PREFIX + name,
                SMS_PREFIX + nameProxy,
                false,
                sdkConvo,
                addNotifications
              );
              emptyData();
              handleSMSClose();
            } catch (e) {
              setErrorData(e as any);
              setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
            }
          }}
        />
      )}
      {isAddWhatsAppOpen && (
        <AddWhatsAppParticipantModal
          name={number}
          proxyName={localStorage.getItem("partnerPhone") ?? ""}
          isModalOpen={isAddWhatsAppOpen}
          title="Gerenciar Participantes"
          setName={(name: string) => {
            setNumber(name);
            setErrors("");
          }}
          setProxyName={(name: string) => {
            setNameProxy(name);
            setErrors("");
          }}
          error={error}
          errorProxy={errorProxy}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleWhatsAppClose();
          }}
          onBack={() => {
            emptyData();
            handleWhatsAppClose();
            setIsManageParticipantOpen(true);
          }}
          action={async () => {
            const partnerPhone = localStorage.getItem("partnerPhone");
            try {
              await addParticipant(
                WHATSAPP_PREFIX + number,
                WHATSAPP_PREFIX + partnerPhone?.replace("+", ""),
                false,
                sdkConvo,
                addNotifications
              );
              emptyData();
              handleWhatsAppClose();
            } catch (e) {
              setErrorData(e as any);
              setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
            }
          }}
        />
      )}
      {isAddChatOpen && (
        <AddChatParticipantModal
          name={name}
          isModalOpen={isAddChatOpen}
          title="Gerenciar Participantes"
          setName={(name: string) => {
            setName(name);
            setErrors("");
          }}
          error={error}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleChatClose();
          }}
          onBack={() => {
            emptyData();
            handleChatClose();
            setIsManageParticipantOpen(true);
          }}
          action={async () => {
            try {
              await addParticipant(
                name,
                nameProxy,
                true,
                sdkConvo,
                addNotifications
              );
              emptyData();
            } catch (e) {
              setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
              setErrorData(e as any);
            }
          }}
        />
      )}

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          height="100%"
          width="100%"
        >
          <Spinner size="sizeIcon110" decorative={false} title="Loading" />
        </Box>
      ) : null}
    </>
  );
};

export default Settings;
