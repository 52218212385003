import { Box } from "@twilio-paste/core";
import { ArrowBackIcon } from "@twilio-paste/icons/esm/ArrowBackIcon";
import { EditIcon } from "@twilio-paste/icons/esm/EditIcon";
import { MoreIcon } from "@twilio-paste/icons/esm/MoreIcon";
import { ProductAdminUsersIcon } from "@twilio-paste/icons/esm/ProductAdminUsersIcon";

import {
  MediaBody,
  MediaFigure,
  MediaObject,
} from "@twilio-paste/media-object";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuSeparator,
  useMenuState,
} from "@twilio-paste/menu";
import { Text } from "@twilio-paste/text";
import { NotificationLevel } from "@twilio/conversations";
import React, { useEffect, useMemo, useState } from "react";

import { NOTIFICATION_LEVEL } from "../../constants";
import { getSdkConversationObject } from "../../conversations-objects";
import { unexpectedErrorNotification } from "../../helpers";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { NotificationsType } from "../../store/reducers/notificationsReducer";
import styles from "../../styles";
import Bell from "../icons/Bell";
import BellMuted from "../icons/BellMuted";
import ConversationTitleModal from "../modals/ConversationTitleModal";
import { CloseIcon } from "@twilio-paste/icons/cjs/CloseIcon";

interface SettingsMenuProps {
  leaveConvo: () => void;
  endProposal: () => void;
  updateConvo: (val: string) => Promise<void>;
  atribbuteConvo: () => Promise<void>;
  conversation: ReduxConversation;
  onParticipantListOpen: () => void;
  addNotifications: (messages: NotificationsType) => void;
  disabled: boolean;
  attribute: string[];
}

const SettingsMenu: React.FC<SettingsMenuProps> = (
  props: SettingsMenuProps
) => {
  const menu = useMenuState();

  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const { friendlyName, notificationLevel } = props.conversation;
  const muted = notificationLevel === NOTIFICATION_LEVEL.MUTED;
  const sdkConvo = useMemo(
    () => getSdkConversationObject(props.conversation),
    [props.conversation.sid]
  );
  const formatNumber = (number: string) => {
    if (!number) return "";
    if (!number.includes(" | ")) return number.replace(/[^\d]/g, "");
    let num = number.split(" | ")[1];
    num = num.replace(/[^\d]/g, "");
    if (!num) return "";
    return num;
  };
  const formatToCellphone = (number: string) => {
    const ct = number.substring(0, 2);
    const ddd = number.substring(2, 4);
    const firstPart = number.substring(4, 9);
    const secondPart = number.substring(9, 13);
    return `${ct}(${ddd}) ${firstPart}-${secondPart}`;
  };
  const formatName = (name: string) => {
    if (!name) return "";
    else if (!name.includes(" | ")) return "";
    return name.split(" | ")[0];
  };
  const formatProposal = (name: string) => {
    if (!name) return "";
    else if (!name.includes(" | ")) return "";
    return name.split(" | ")[2];
  };
  const toggleMuteConversation = () => {
    sdkConvo.setUserNotificationLevel(
      muted
        ? (NOTIFICATION_LEVEL.DEFAULT as NotificationLevel)
        : (NOTIFICATION_LEVEL.MUTED as NotificationLevel)
    );
  };

  useEffect(() => {
    if (friendlyName) {
      setTitle(formatName(friendlyName));
      setNumber(formatNumber(friendlyName));
      setProposal(formatProposal(friendlyName));
    }
  }, [friendlyName]);

  const [title, setTitle] = useState(formatName(friendlyName ?? ""));
  const [number, setNumber] = useState(formatNumber(friendlyName ?? ""));
  const [proposal, setProposal] = useState(formatProposal(friendlyName ?? ""));

  return (
    <Box style={styles.settingsWrapper}>
      <MenuButton {...menu} variant="link" size="reset">
        <MoreIcon decorative={false} title="Configurações" />
      </MenuButton>
      <Menu {...menu} aria-label="Preferences">
        <Box style={styles.optionWrapper}>
          <MenuItem {...menu}>
            <MediaObject verticalAlign="center">
              <MediaFigure spacing="space20">
                <EditIcon
                  decorative={false}
                  title="edit"
                  color="colorTextIcon"
                />
              </MediaFigure>
              <MediaBody onClick={() => setIsTitleModalOpen(true)}>
                Editar título
              </MediaBody>
              <ConversationTitleModal
                number={number}
                setNumber={setNumber}
                title={title}
                setTitle={setTitle}
                proposal={proposal}
                setProposal={setProposal}
                type="edit"
                isModalOpen={isTitleModalOpen}
                onCancel={() => {
                  setIsTitleModalOpen(false);
                }}
                onSave={async () => {
                  try {
                    await props.updateConvo(
                      title + " | " + "+" + number + " | " + proposal
                    );
                  } catch {
                    unexpectedErrorNotification(props.addNotifications);
                  }
                  setIsTitleModalOpen(false);
                }}
              />
            </MediaObject>
          </MenuItem>
        </Box>
        {/* <MenuItem {...menu}>
          <MediaObject verticalAlign="center" onClick={toggleMuteConversation}>
            <MediaFigure spacing="space20">
              {muted ? <Bell /> : <BellMuted />}
            </MediaFigure>
            <MediaBody>{muted ? "Desmutar" : "Mutar"} conversa</MediaBody>
          </MediaObject>
        </MenuItem> */}
        <MenuItem
          {...menu}
          onClick={props.onParticipantListOpen}
          disabled={props.disabled}
        >
          <MediaObject verticalAlign="center">
            <MediaFigure spacing="space20">
              <ProductAdminUsersIcon
                decorative={false}
                title="information"
                color="colorTextIcon"
              />
            </MediaFigure>
            <MediaBody>Gerenciar participantes</MediaBody>
          </MediaObject>
        </MenuItem>
        <p style={{ fontSize: "12px", marginLeft: "24px" }}>
          Conversa atribuída á: <br />
          {props.attribute && props.attribute.length
            ? props.attribute.includes(localStorage.getItem("username") ?? "")
              ? "meu usuário"
              : `${props.attribute.join(", ")}`
            : "nenhum usuário"}
        </p>
        {/* <MenuItem {...menu} onClick={props.atribbuteConvo}>
          <MediaObject verticalAlign="center">
            <MediaFigure spacing="space20">
              <UserIcon
                decorative={false}
                title="information"
                color="colorTextIcon"
              />
            </MediaFigure>
            <MediaBody>Atribuir á mim</MediaBody>
          </MediaObject>
        </MenuItem> */}
        <MenuSeparator {...menu} />
        <MenuItem
          {...menu}
          onClick={props.endProposal}
          disabled={props.disabled}
        >
          <MediaObject verticalAlign="center">
            <MediaFigure spacing="space20">
              <CloseIcon
                decorative={false}
                title="information"
                color="colorTextError"
              />
            </MediaFigure>
            <MediaBody>
              <Text as="a" color="colorTextError">
                Encerrar conversa
              </Text>
            </MediaBody>
          </MediaObject>
        </MenuItem>
        {/* <MenuItem
          {...menu}
          onClick={props.leaveConvo}
          disabled={props.disabled}
        >
          <MediaObject verticalAlign="center">
            <MediaFigure spacing="space20">
              <ArrowBackIcon
                decorative={false}
                title="information"
                color="colorTextError"
              />
            </MediaFigure>
            <MediaBody>
              <Text as="a" color="colorTextError">
                Finalizar proposta
              </Text>
            </MediaBody>
          </MediaObject>
        </MenuItem> */}
      </Menu>
    </Box>
  );
};

export default SettingsMenu;
