import { Box, ModalBody } from "@twilio-paste/core";
import { RefObject, useEffect, useState } from "react";
import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
import { getUsersFromTwilio } from "../../services/api";
import AddParticipantFooter from "./addParticipantFooter";

interface AddChatParticipantModalProps {
  name: string;
  setName: (name: string) => void;
  error: string;
  nameInputRef: RefObject<HTMLInputElement>;
  onBack: () => void;
  action: () => void;
  handleClose: () => void;
  isModalOpen: boolean;
  title: string;
}

const AddChatParticipantModal: React.FC<AddChatParticipantModalProps> = (
  props: AddChatParticipantModalProps
) => {
  const [users, setUsers] = useState<unknown[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      const partner = localStorage.getItem("partner") ?? "";
      try {
        const fetchedUsers = await getUsersFromTwilio(partner);
        setUsers(fetchedUsers as unknown as unknown[]);
      } catch (e) {
        setError("Erro ao buscar usuários da Twilio");
      }
    };

    fetchUsers();
  }, []);

  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            <h3>Adicionar participante Chat</h3>
            <Box as="form">
              {/* <ModalInputField
                label="Nome de usuário"
                isFocused={true}
                input={props.name}
                placeholder="Digite aqui"
                onChange={props.setName}
                error={props.error}
                // error_text="Enter a valid user identity."
                help_text="A identidade usada pelo participante em Conversas."
              /> */}
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Nome de usuário
              </label>
              <select
                id="numproxies"
                onChange={(a) => props.setName(a.currentTarget.value)}
                value={props.name}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value="">
                  {users.length ? "Selecione" : error || "Buscando usuários..."}
                </option>
                {users.map((user: any) => (
                  <option key={user.username} value={user.username}>
                    {user.name}
                  </option>
                ))}
              </select>
            </Box>
          </ModalBody>
        }
        modalFooter={
          <AddParticipantFooter
            isSaveDisabled={!props.name.trim() || !!props.error}
            actionName={ActionName.Transfer}
            onBack={() => {
              props.onBack();
            }}
            action={props.action}
          />
        }
      />
    </>
  );
};

export default AddChatParticipantModal;
