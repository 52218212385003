export const WHATSAPP_PREFIX = "whatsapp:+";
export const SMS_PREFIX = "+";
export const MAX_FILE_SIZE = 52428800;
export const COPY_SUCCESS_MESSAGE = "Mensagem copiada.";
export const UNEXPECTED_ERROR_MESSAGE =
  "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.";
export const CONNECTION_ERROR_MESSAGE = "Sem conexão com a internet.";
export const NOTIFICATION_TIMEOUT = 4000;
export const ERROR_MODAL_MESSAGES = {
  SID_NOT_FOUND: {
    title: "Erro ao reativar conversa",
    description: "Não foi possível encontrar o SID.",
  },
  ADD_PARTICIPANT: {
    title: "Não foi possível adicionar o participante",
    description:
      "O número de Whatsapp desse participante já está vinculado á uma conversa.",
  },
  ADD_USERS: {
    title: "Não foi possível adicionar os usuários",
    description:
      "Não foi possível adicionar os usuários. Por favor, tente novamente mais tarde.",
  },
  SEARCH_USERS: {
    title: "Não foi possível buscar os usuários",
    description:
      "Não foi possível buscar os usuários. Por favor, tente novamente mais tarde.",
  },
  CHANGE_CONVERSATION_NAME: {
    title: "Não foi possível salvar o título da conversa",
    description:
      "Somente os criadores da Conversa podem editar o título da Conversa.",
  },
  ATTRIBUTE_TO_ME: {
    title: "Erro de atribuição",
    description:
      "Não foi possível atribuir a conversa para você. Por favor, tente novamente mais tarde.",
  },
  ATTRIBUTE_TO_OTHER: {
    title: "Erro de atribuição",
    description:
      "Não foi possível atribuir a conversa para outro usuário. Por favor, tente novamente mais tarde.",
  },
  DISATTRIBUTE: {
    title: "Erro de desatribuição",
    description:
      "Não foi possível desatribuir a conversa. Por favor, tente novamente mais tarde.",
  },
  CREATE_CONVO: {
    title: "Erro de criação",
    description:
      "Não foi possível criar a conversa. Por favor, tente novamente mais tarde.",
  },
  ALREADY_EXISTS: {
    title: "Erro ao reativar conversa",
    description:
      "Já existe uma conversa aberta com esse número de telefone, verifique na lista ou entre em contato com o suporte.",
  },
  CONFIRM_FINISH_CONVO: {
    title: "Deseja finalizar a conversa?",
    description:
      "Ao finalizar a proposta, você não poderá mais enviar mensagens para esse número.",
  },
  SEARCH_CONVERSATIONS: {
    title: "Não foi possível buscar as conversas",
    description:
      "Não foi possível buscar as conversas. Por favor, tente novamente mais tarde.",
  },
  CONTACT_SUPPORT: {
    title: "Não foi possível criar a conversa",
    description: "Entre em contato com o Suporte, código de erro: ",
  },
};
export const CONVERSATION_MESSAGES = {
  CREATED: "Conversa criada.",
  NAME_CHANGED: "Título da conversa alterado.",
  LEFT: "Você saiu da conversa.",
  ATTRIBUTED: "Conversa atribuída para outro usuário.",
  ATTRIBUTED_TO_ME: "Conversa atribuída para você.",
  DISATTRIBUTE: "Conversa desatribuída, não possui atribuições.",
  DISABLED_CONVO: "Conversa desativada.",
  CREATE_ERROR: "Não foi possível criar a conversa.",
  RESTARTED_SUCCESSFULLY: "Conversa reativada.",
};

export const PARTICIPANT_MESSAGES = {
  ADDED: "Participante adicionado.",
  REMOVED: "Participante removido.",
};

export const CONVERSATION_PAGE_SIZE = 30;

export const NOTIFICATION_LEVEL = {
  DEFAULT: "default",
  MUTED: "muted",
};
