import {
  Anchor,
  Box,
  Button,
  ModalBody,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "@twilio-paste/core";
import { Text } from "@twilio-paste/text";
import ConvoModal from "./ConvoModal";

interface ConvoListProps {
  conversationsCount: number;
  handleClose: () => void;
  isModalOpen: boolean;
  title: string;
  convosList: any[];
  onRestartConvo: (convo: any) => void;
  onLoadMore: () => void;
  isSubmitting: boolean;
  isReactivating: boolean;
  searchValue: string;
  totalClosedConvos: number;
  limit: number;
}

const ConvoListModal: React.FC<ConvoListProps> = (props: ConvoListProps) => {
  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "6px",
              }}
            >
              <Box
                fontFamily="fontFamilyText"
                fontWeight="fontWeightBold"
                fontSize="fontSize30"
                lineHeight="lineHeight60"
              >
                Encontradas {props.conversationsCount} conversa(s) arquivada(s)
                para a busca "{props.searchValue}"
              </Box>
            </Box>
            <Box
              style={{
                marginTop: "16px",
                overflow: "hidden",
              }}
            >
              {props.convosList.length ? (
                <>
                  <Table style={{ marginBottom: 20 }}>
                    <THead>
                      <Tr>
                        <Th>Título da conversa</Th>
                        <Th textAlign="right">Ação</Th>
                      </Tr>
                    </THead>
                    <TBody>
                      {props.convosList.map((convo) => (
                        <Tr key={convo.id}>
                          <Td>{convo.conversation.friendly_name}</Td>

                          <Td textAlign="right">
                            <Anchor
                              href="#"
                              onClick={() => props.onRestartConvo(convo)}
                            >
                              {props.isReactivating
                                ? "Reativando..."
                                : "Reativar"}
                            </Anchor>
                          </Td>
                        </Tr>
                      ))}
                    </TBody>
                  </Table>
                  <Box
                    style={{
                      margin: "16px 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    Mostrando {props.convosList.length} de{" "}
                    {props.totalClosedConvos}
                    <Button
                      variant="secondary"
                      onClick={() => props.onLoadMore()}
                      disabled={
                        props.convosList.length >= props.totalClosedConvos
                      }
                    >
                      {props.isSubmitting ? "Carregando..." : "Carregar mais"}
                    </Button>
                  </Box>
                </>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100px",
                  }}
                >
                  <Box
                    style={{
                      color: "#606B85",
                    }}
                  >
                    <Text
                      as="p"
                      fontSize="fontSize40"
                      style={{
                        color: "#606B85",
                      }}
                    >
                      Nenhuma conversa arquivada
                    </Text>
                  </Box>
                </Box>
              )}
            </Box>
          </ModalBody>
        }
      />
    </>
  );
};

export default ConvoListModal;
