import { Client, Conversation } from "@twilio/conversations";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ERROR_MODAL_MESSAGES } from "../../constants";

import { WHATSAPP_PREFIX } from "../../constants";
import { addParticipant, disableConversation } from "../../services/api";
import { AppState, actionCreators } from "../../store";
import ActionErrorModal from "../modals/ActionErrorModal";
import AddWhatsAppParticipantModal from "../modals/addWhatsAppParticipant";

import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";

import {
  addConversation,
  getUsersFromTwilio,
  createConversation,
} from "../../services/api";
import ConversationTitleModal from "../modals/ConversationTitleModal";
import {
  successNotification,
  unexpectedErrorNotification,
} from "../../helpers";
interface NewConvoProps {
  client?: Client;
  collapsed: boolean;
}

const CreateConversationButton: React.FC<NewConvoProps> = (
  props: NewConvoProps
) => {
  const [isCreatingOrReactivating, setIsCreatingOrReactivating] =
    useState(false);
  const [propsConvo, setPropsConvo] = useState<Conversation>();
  const [number, setNumber] = useState("55");
  const [title, setTitle] = useState("");
  const [proposal, setProposal] = useState("");

  const [isAddChatOpen, setIsAddChatOpen] = useState(false);
  // TODO: move to app loading state
  const handleChatOpen = () => setIsAddChatOpen(true);
  const handleChatClose = () => setIsAddChatOpen(false);

  const [isAddSMSOpen, setIsAddSMSOpen] = useState(false);
  const handleSMSOpen = () => setIsAddSMSOpen(true);
  const handleSMSClose = () => setIsAddSMSOpen(false);

  const [isAddWhatsAppOpen, setIsAddWhatsAppOpen] = useState(false);
  const handleWhatsAppOpen = () => setIsAddWhatsAppOpen(true);
  const handleWhatsAppClose = () => setIsAddWhatsAppOpen(false);

  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const [nameProxy, setNameProxy] = useState("");
  const [errorProxy, setErrorProxy] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);

  const [showError, setErrorToShow] = useState<
    | {
        title: string;
        description: string;
      }
    | false
  >();
  const [errorData, setErrorData] = useState<
    | {
        message: string;
        code: number;
      }
    | undefined
  >();

  const formatNumber = (number: string) => {
    const ct = number.substring(0, 2);
    const ddd = number.substring(2, 4);
    const firstPart = number.substring(4, 9);
    const secondPart = number.substring(9, 13);
    return `${ct}(${ddd}) ${firstPart}-${secondPart}`;
  };
  const sid = useSelector((state: AppState) => state.sid);
  const participants =
    useSelector((state: AppState) => state.participants)[sid] ?? [];

  const nameInputRef = createRef<HTMLInputElement>();

  const dispatch = useDispatch();
  const {
    updateCurrentConversation,
    updateParticipants,
    addNotifications,
    updateConversation,
  } = bindActionCreators(actionCreators, dispatch);

  // const sdkConvo: any = useMemo(
  //   () => getSdkConversationObject(propsConvo),
  //   [propsConvo?.sid]
  // );

  const atribbuteConvo = async (users: unknown[], sdkConvo: Conversation) => {
    const usersAdmin: string[] = [];
    let attributes =
      typeof sdkConvo?.attributes === "string"
        ? JSON.parse(sdkConvo?.attributes)
        : null;
    users.forEach((user: any) => {
      if (user.username === localStorage.getItem("username")) {
        usersAdmin.push(user.username);
      } else if (user.twilioAdmin) usersAdmin.push(user.username);
    });
    attributes = {
      isOwner: usersAdmin,
      lastMessage: attributes?.lastMessage ?? "",
    };
    sdkConvo
      ?.updateAttributes(attributes)
      .then((convo) => {
        updateConversation(convo.sid, convo);
      })
      .catch((e) => {
        setErrorData(e);
        setErrorToShow(ERROR_MODAL_MESSAGES.ATTRIBUTE_TO_ME);
      });
  };

  const handleRestartConvo = async (obj?: { secondTime: boolean }) => {
    setIsCreatingOrReactivating(true);
    try {
      const convoId = await createConversation(
        {
          name: title + " | " + "+" + number + " | " + proposal,
          number: number.replace("+", ""),
          partner: localStorage.getItem("partner") as string,
        },
        addNotifications
      );
      setIsCreatingOrReactivating(false);
      emptyData();
      setIsModalOpen(false);
      updateCurrentConversation(convoId);
    } catch (response: any) {
      let sid = "";
      if (response?.status === 409) {
        const message = response?.data?.message || response?.message;
        const match = message?.match(
          /(?:Sid:\s*([A-Za-z0-9]+)|Conversation\s+([A-Za-z0-9]+))/i
        );

        if (match) {
          sid = match[1] || match[2];

          if (!obj) {
            await handleDisableConvo(sid);
          } else {
            setIsCreatingOrReactivating(false);
            updateCurrentConversation(sid);
            successNotification({
              message: response?.data?.message,
              addNotifications,
            });
            setIsModalOpen(false);
            emptyData();
          }
        } else {
          emptyData();
          setIsModalOpen(false);
          setIsCreatingOrReactivating(false);
          setErrorData(response);
          setErrorToShow(ERROR_MODAL_MESSAGES.SID_NOT_FOUND);
        }
      } else if (response?.status === 502) {
        emptyData();
        setIsModalOpen(false);
        setIsCreatingOrReactivating(false);
        setErrorData(response);
        setErrorToShow(ERROR_MODAL_MESSAGES.ALREADY_EXISTS);
      } else if (response?.status === 400) {
        emptyData();
        setIsModalOpen(false);
        setErrorData(response);
        setIsCreatingOrReactivating(false);
        setErrorToShow({
          ...ERROR_MODAL_MESSAGES.CONTACT_SUPPORT,
          description:
            ERROR_MODAL_MESSAGES.CONTACT_SUPPORT.description + response?.code,
        });
      }
    }
  };

  const handleDisableConvo = async (sid: string) => {
    const partner = localStorage.getItem("partner");
    updateCurrentConversation("");

    try {
      await disableConversation(sid, partner as string, addNotifications);
      handleRestartConvo({ secondTime: true });
      return Promise.resolve();
    } catch {
      unexpectedErrorNotification(addNotifications);
      setIsCreatingOrReactivating(false);
      return Promise.reject();
    }
  };

  const addNewParticipants = async (
    convo: Conversation | undefined,
    number: string
  ) => {
    const partner = localStorage.getItem("partner");
    const partnerPhone = localStorage.getItem("partnerPhone");
    await addParticipantToConversation(
      WHATSAPP_PREFIX + number,
      partnerPhone,
      false,
      convo,
      addNotifications
    );
    const users = await fetchUsers(partner as string);
    atribbuteConvo(users, convo as Conversation);
    const addUserPromises = users.map((user: any) => {
      if (user.username !== localStorage.getItem("username"))
        return addParticipantToConversation(
          user.username,
          partnerPhone,
          true,
          convo,
          addNotifications
        );
    });

    await Promise.all(addUserPromises);

    emptyData();
    // handleWhatsAppClose();
  };

  useEffect(() => {
    const partner = localStorage.getItem("partner");

    fetchUsers(partner as string);
  }, []);

  const addParticipantToConversation = async (
    userProxy: string,
    partnerPhone: string | null,
    flag: boolean,
    convo: Conversation | undefined,
    addNotifications: any
  ) => {
    const partnerNumber = WHATSAPP_PREFIX + partnerPhone?.replace("+", "");
    try {
      await addParticipant(
        userProxy,
        partnerNumber,
        flag,
        convo,
        addNotifications
      );
    } catch (e) {
      emptyData();
      setErrorData(e as any);
      if (flag) setErrorToShow(ERROR_MODAL_MESSAGES.ADD_USERS);
      else setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
    }
  };

  const fetchUsers = async (partner: string) => {
    try {
      const users = await getUsersFromTwilio(partner);
      return users as any;
    } catch (e) {
      setErrorData(e as any);
      setErrorToShow(ERROR_MODAL_MESSAGES.SEARCH_USERS);
    }
  };

  function emptyData() {
    setName("");
    setNameProxy("");
    setNumber("55");
    setTitle("");
    setProposal("");
    setError("");
    setErrorProxy("");
  }

  function setErrors(errorText: string) {
    setError(errorText);
    setErrorProxy(errorText);
  }
  return (
    <>
      <Button fullWidth variant="secondary" onClick={handleOpen}>
        <PlusIcon decorative={false} title="Adicionar conversa" />
        {!props.collapsed ? "Criar nova conversa" : null}
      </Button>
      <ConversationTitleModal
        title={title}
        setTitle={setTitle}
        number={number}
        setNumber={setNumber}
        proposal={proposal}
        setProposal={setProposal}
        type="new"
        isModalOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          emptyData();
        }}
        onSave={() => handleRestartConvo()}
        isCreatingOrReactivating={isCreatingOrReactivating}
      />
      <ActionErrorModal
        errorText={showError || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME}
        isOpened={!!showError}
        onClose={() => {
          setErrorToShow(false);
          setErrorData(undefined);
        }}
        error={errorData}
      />
      {isAddWhatsAppOpen && (
        <AddWhatsAppParticipantModal
          name={number}
          proxyName={"551150397349"}
          isModalOpen={isAddWhatsAppOpen}
          title="Gerenciar Participantes"
          setName={(name: string) => {
            setName(name);
            setErrors("");
          }}
          disabledInput={true}
          setProxyName={(name: string) => {
            setNameProxy(name);
            setErrors("");
          }}
          error={error}
          errorProxy={errorProxy}
          nameInputRef={nameInputRef}
          handleClose={() => {
            emptyData();
            handleWhatsAppClose();
          }}
          onBack={() => {
            emptyData();
            handleWhatsAppClose();
          }}
          action={async () => {
            try {
              await addParticipant(
                WHATSAPP_PREFIX + number,
                WHATSAPP_PREFIX + nameProxy,
                false,
                propsConvo,
                addNotifications
              );
              emptyData();
              handleWhatsAppClose();
            } catch (e) {
              setErrorData(e as any);
              setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
            }
          }}
        />
      )}
    </>
  );
};

export default CreateConversationButton;
