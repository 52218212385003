import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { useEffect, useState } from "react";
import "../../assets/style.css";
import {
  MessageStatus,
  ReduxMessage,
} from "../../store/reducers/messageListReducer";
import BellMuted from "../icons/BellMuted";
import DeliveredIcon from "../icons/Delivered";
import FailedIcon from "../icons/Failed";
import ReadIcon from "../icons/Read";
import SendingIcon from "../icons/Sending";

import { NOTIFICATION_LEVEL } from "../../constants";
import { getMessageStatus } from "../../services/api";
import { SetSidType, SetUnreadMessagesType } from "../../types";

import * as _ from "lodash";

import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import TimeAgo from "javascript-time-ago";
import pt from "javascript-time-ago/locale/pt"; // Importe o idioma pt em vez de pt-BR

TimeAgo.addDefaultLocale(pt);

interface SingleConvoProps {
  convoId: string;
  setSid: SetSidType;
  currentConvoSid: string;
  // finished: boolean;
  atributedToOthers: boolean;
  lastMessage: string;
  myMessage: ReduxMessage | false;
  unreadMessagesCount: number;
  convo: ReduxConversation;
  updateUnreadMessages: SetUnreadMessagesType;
  onClick: () => void;
  participants: ReduxParticipant[];
  messages: ReduxMessage[];
  typingInfo: string[];
}

const measureWidth = (text: string): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    return 0;
  }
  context.font = "bold 14px Inter";
  return context.measureText(text).width;
};

function calculateUnreadMessagesWidth(count: number) {
  if (count === 0 || !count) {
    return 0;
  }
  return measureWidth(count.toString()) + 32;
}
function truncateMiddle(text: string, countWidth: number) {
  const width = measureWidth(text);
  if (width > 288 - countWidth) {
    const textLength = text.length;
    const avgLetterSize = width / textLength;
    const nrOfLetters = (288 - countWidth) / avgLetterSize;
    const delEachSide = (textLength - nrOfLetters + 1) / 2;
    const endLeft = Math.floor(textLength / 2 - delEachSide);
    const startRight = Math.ceil(textLength / 2 + delEachSide);
    return text.substr(0, endLeft) + "..." + text.substr(startRight);
  }
  return text;
}

function getLastMessageTime(messages: ReduxMessage[]) {
  const lastMessageDate = _.last(messages)?.dateCreated;
  if (!lastMessageDate) {
    return "";
  }

  const currentDate = new Date();
  const messageDate = new Date(lastMessageDate);

  if (
    messageDate.getDate() === currentDate.getDate() &&
    messageDate.getMonth() === currentDate.getMonth() &&
    messageDate.getFullYear() === currentDate.getFullYear()
  ) {
    const hours = String(messageDate.getHours()).padStart(2, "0");
    const minutes = String(messageDate.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  } else {
    return new TimeAgo(pt.locale).format(messageDate, "twitter");
  }
}

const ConversationView: React.FC<SingleConvoProps> = (
  props: SingleConvoProps
) => {
  const { convo, convoId, myMessage, lastMessage, unreadMessagesCount } = props;
  const [backgroundColor, setBackgroundColor] = useState();
  const title = truncateMiddle(
    convo.friendlyName ?? convo.sid,
    calculateUnreadMessagesWidth(unreadMessagesCount)
  );
  const theme = useTheme();
  const textColor =
    unreadMessagesCount > 0
      ? theme.textColors.colorText
      : theme.textColors.colorTextIcon;
  const muted = convo.notificationLevel === NOTIFICATION_LEVEL.MUTED;

  const [lastMsgStatus, setLastMsgStatus] = useState("");
  const time = getLastMessageTime(props.messages);

  useEffect(() => {
    if (props.currentConvoSid === convo.sid) {
      setBackgroundColor(theme.backgroundColors.colorBackgroundStrong);
      return;
    }
    setBackgroundColor(theme.backgroundColors.colorBackgroundRowStriped);
  }, [props.currentConvoSid, convo.sid]);

  useEffect(() => {
    if (myMessage && !props.typingInfo.length) {
      getMessageStatus(myMessage, props.participants).then((statuses) => {
        if (statuses[MessageStatus.Read]) {
          setLastMsgStatus(MessageStatus.Read);
          return;
        }
        if (statuses[MessageStatus.Delivered]) {
          setLastMsgStatus(MessageStatus.Delivered);
          return;
        }
        if (statuses[MessageStatus.Failed]) {
          setLastMsgStatus(MessageStatus.Failed);
          return;
        }
        if (statuses[MessageStatus.Sending]) {
          setLastMsgStatus(MessageStatus.Sending);
          return;
        }
      });
    }
  }, [convo, myMessage, lastMessage, props.participants, props.typingInfo]);

  return (
    <Box
      style={{
        width: 300,
        height: 115,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 16,
        paddingRight: 16,
        cursor: "pointer",
        backgroundColor: backgroundColor,
        borderBottom: `2px solid #fff`,
      }}
      id={convoId}
      className="name"
      onMouseOver={() => {
        if (convo.sid === props.currentConvoSid) {
          return;
        }
        setBackgroundColor(theme.backgroundColors.colorBackgroundStrong);
      }}
      onMouseOut={() => {
        if (convo.sid === props.currentConvoSid) {
          return;
        }
        setBackgroundColor(theme.backgroundColors.colorBackgroundRowStriped);
      }}
      onClick={props.onClick}
    >
      <Box
        style={{
          backgroundColor: backgroundColor,
        }}
        className={props.atributedToOthers ? "alert-atributedOther" : ""}
      >
        {props.atributedToOthers ? (
          <small>Conversa atribuída a outro atendente</small>
        ) : null}

        <Box display="flex">
          <Box
            style={{
              width: 288,
              fontFamily: "Inter",
              fontWeight: theme.fontWeights.fontWeightMedium,
              fontSize: 14,
              color: muted
                ? theme.textColors.colorTextInverseWeaker
                : theme.textColors.colorText,
            }}
          >
            {muted ? <BellMuted /> : null}
            <span style={{ verticalAlign: "top", paddingLeft: muted ? 4 : 0 }}>
              {title}
            </span>
          </Box>
          {unreadMessagesCount > 0 && (
            <Box paddingLeft="space30">
              <Box
                backgroundColor="colorBackgroundBrandStronger"
                color="colorTextInverse"
                fontFamily="fontFamilyText"
                fontWeight="fontWeightBold"
                fontSize="fontSize30"
                lineHeight="lineHeight30"
                paddingLeft="space30"
                paddingRight="space30"
                style={{ borderRadius: 12, opacity: muted ? 0.2 : 1 }}
              >
                {unreadMessagesCount}
              </Box>
            </Box>
          )}
        </Box>
        <Box
          style={{
            paddingTop: 4,
            color: textColor,
            fontWeight: theme.fontWeights.fontWeightNormal,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {!props.typingInfo.length ? (
              <Box>
                {lastMsgStatus === MessageStatus.Sending && props.myMessage && (
                  <Box style={{ paddingRight: 6 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="14px"
                      viewBox="0 0 24 18"
                      width="14px"
                      fill="#4e4e4b"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                    </svg>
                  </Box>
                )}
                {lastMsgStatus === MessageStatus.Delivered && props.myMessage && (
                  <Box style={{ paddingRight: 6 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="14px"
                      viewBox="0 0 24 18"
                      width="14px"
                      fill="#4e4e4b"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
                    </svg>
                  </Box>
                )}
                {lastMsgStatus === MessageStatus.Failed && props.myMessage && (
                  <Box style={{ paddingRight: 6 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      enable-background="new 0 0 24 24"
                      height="14px"
                      viewBox="0 0 24 18"
                      width="14px"
                      fill="#4e4e4b"
                    >
                      <g>
                        <rect fill="none" height="24" width="24" x="0" />
                      </g>
                      <g>
                        <g>
                          <g>
                            <path d="M21,10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-0.1c-2.73,2.71-2.73,7.08,0,9.79s7.15,2.71,9.88,0 C18.32,15.65,19,14.08,19,12.1h2c0,1.98-0.88,4.55-2.64,6.29c-3.51,3.48-9.21,3.48-12.72,0c-3.5-3.47-3.53-9.11-0.02-12.58 s9.14-3.47,12.65,0L21,3V10.12z M12.5,8v4.25l3.5,2.08l-0.72,1.21L11,13V8H12.5z" />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </Box>
                )}
                {lastMsgStatus === MessageStatus.Read && props.myMessage && (
                  <Box style={{ paddingRight: 6 }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="14px"
                      viewBox="0 0 24 18"
                      width="14px"
                      fill="#25da2e"
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
                    </svg>
                  </Box>
                )}
              </Box>
            ) : null}
            <Box
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {lastMessage}
            </Box>
          </Box>
          <Box style={{ whiteSpace: "nowrap", paddingLeft: 4 }}>{time}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationView;
