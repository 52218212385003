import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { bindActionCreators } from "redux";
import {
  ERROR_MODAL_MESSAGES,
  UNEXPECTED_ERROR_MESSAGE,
} from "../../constants";
import { getSdkConversationObject } from "../../conversations-objects";
import {
  getTypingMessage,
  successNotification,
  unexpectedErrorNotification,
} from "../../helpers";
import { AppState, actionCreators } from "../../store";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxMessage } from "../../store/reducers/messageListReducer";
import {
  SetParticipantsType,
  SetSidType,
  SetUnreadMessagesType,
} from "../../types";
import ConversationView from "./ConversationView";
import { JSONObject } from "@twilio/conversations";
import { Button, Checkbox } from "@twilio-paste/core";
import { createConversation, fetchClosedConvos } from "../../services/api";
import ConvoListModal from "../modals/ModalConvosList";
import ActionErrorModal from "../modals/ActionErrorModal";

const ConversationsList: React.FC = () => {
  const sid = useSelector((state: AppState) => state.sid);
  const conversations = useSelector((state: AppState) => state.convos);
  const messages = useSelector((state: AppState) => state.messages);
  const unreadMessages = useSelector((state: AppState) => state.unreadMessages);
  const participants = useSelector((state: AppState) => state.participants);
  const typingData = useSelector((state: AppState) => state.typingData);
  const [convoFiltered, setConvoFilter] = useState([] as ReduxConversation[]);
  const [convosVisible, setConvosVisible] = useState(5);
  // const [showOpenedConvo, setShowOpenedConvo] = useState(false);
  const [showMyConvos, setShowMyConvos] = useState(false);
  const [maxConvosOpen, setMaxConvosOpen] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [closedConvos, setClosedConvos] = useState([] as any[]);
  const [limit, setLimit] = useState(10);
  const [totalClosedConvos, setTotalClosedConvos] = useState(0);
  const [showError, setErrorToShow] = useState<
    | {
        title: string;
        description: string;
      }
    | false
  >();
  const [errorData, setErrorData] = useState<
    | {
        message: string;
        code: number;
      }
    | undefined
  >();

  const dispatch = useDispatch();
  const {
    updateCurrentConversation,
    updateParticipants,
    updateUnreadMessages,
    setLastReadIndex,
    addNotifications,
  } = bindActionCreators(actionCreators, dispatch);

  if (conversations === undefined || conversations === null) {
    return <div className="empty" />;
  }

  function getLastMessage(messages: ReduxMessage[], typingData: string[]) {
    if (messages === undefined || messages === null) {
      return "Carregando...";
    }
    if (typingData.length) {
      return getTypingMessage(typingData);
    }
    if (messages.length === 0) {
      return "Sem mensagens";
    }
    return messages[messages.length - 1].body || "Media message";
  }

  function isMyMessage(messages: ReduxMessage[]) {
    if (messages === undefined || messages === null || messages.length === 0) {
      return false;
    }
    return messages[messages.length - 1].author ===
      localStorage.getItem("username")
      ? messages[messages.length - 1]
      : false;
  }

  async function updateCurrentConvo(
    setSid: SetSidType,
    convo: ReduxConversation,
    updateParticipants: SetParticipantsType
  ) {
    setSid(convo.sid);

    try {
      const participants = await getSdkConversationObject(
        convo
      ).getParticipants();
      updateParticipants(participants, convo.sid);
    } catch {
      return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
  }

  function sdkConvo(convo: ReduxConversation) {
    try {
      return getSdkConversationObject(convo);
    } catch (error) {
      return null;
    }
  }

  function setUnreadMessagesCount(
    currentconvoSid: string,
    convoSid: string,
    unreadMessages: Record<string, number>,
    updateUnreadMessages: SetUnreadMessagesType,
    convo: ReduxConversation
  ) {
    const loggedInUser = localStorage.getItem("username") ?? "";
    const SDKCONVO = sdkConvo(convo);
    const attributes = SDKCONVO?.attributes as JSONObject | null;
    let isOwnerValue;
    const lastMessage =
      getLastMessage(messages[convoSid], typingData[convoSid] ?? []) ?? "";
    if (attributes?.finished && attributes?.lastMessage === lastMessage) {
      return 0;
    }
    if (
      attributes?.isOwner &&
      Array.isArray(attributes.isOwner) &&
      attributes.isOwner.length
    ) {
      isOwnerValue = attributes.isOwner[0];
      if (isOwnerValue !== loggedInUser) {
        return 0;
      }
    }
    if (currentconvoSid == convoSid && unreadMessages[convoSid] !== 0) {
      updateUnreadMessages(convoSid, 0);
      return 0;
    }
    if (currentconvoSid == convoSid) {
      return 0;
    }
    return unreadMessages[convoSid];
  }

  // function isConvoFinished(convo: ReduxConversation) {
  //   const SDKCONVO = sdkConvo(convo);
  //   const attributes = SDKCONVO?.attributes as JSONObject | null;
  //   if (attributes?.finished) return true;

  //   return false;
  // }

  const checkOwner = (convo: ReduxConversation) => {
    const SDKCONVO = sdkConvo(convo);
    const attributes = SDKCONVO?.attributes as JSONObject | null;

    return (
      attributes?.isOwner &&
      Array.isArray(attributes.isOwner) &&
      attributes.isOwner.length &&
      attributes?.isOwner?.includes(localStorage.getItem("username") ?? "")
    );
  };

  const hasOtherOwners = (convo: ReduxConversation) => {
    const SDKCONVO = sdkConvo(convo);
    const attributes = SDKCONVO?.attributes as JSONObject | null;

    return (attributes?.isOwner &&
      Array.isArray(attributes.isOwner) &&
      !attributes?.isOwner?.includes(localStorage.getItem("username") ?? "") &&
      attributes.isOwner.length) as boolean;
  };

  const visibleConvos = (convos: ReduxConversation[]) => {
    const diff = convos.length - convosVisible;
    if (diff < 10) {
      setConvosVisible(convos.length);
      return;
    }
    setConvosVisible(convosVisible + 10);
  };

  const showMoreConvos = () => {
    const convos = [] as ReduxConversation[];

    // if (showOpenedConvo) {
    //   conversations.filter((convo) => {
    //     if (!isConvoFinished(convo)) convos.push(convo);
    //   });
    //   visibleConvos(convos);
    //   return;
    // }
    if (showMyConvos) {
      conversations.filter((convo) => {
        if (checkOwner(convo)) convos.push(convo);
      });
      visibleConvos(convos);
      return;
    }
    visibleConvos(conversations);
  };

  const setFilterConvos = (convos: ReduxConversation[]) => {
    if (convosVisible > convos.length) setConvosVisible(convos.length);
    setConvoFilter(convos.slice(0, convosVisible));
    setMaxConvosOpen(convos.length);
  };

  useEffect(() => {
    const convos = [] as ReduxConversation[];
    // if (showOpenedConvo) {
    //   conversations.filter((convo) => {
    //     if (!isConvoFinished(convo)) convos.push(convo);
    //   });
    //   setFilterConvos(convos);
    // }
    if (showMyConvos) {
      conversations.filter((convo) => {
        if (checkOwner(convo)) convos.push(convo);
      });
      setFilterConvos(convos);
    }
    //  else if (showMyConvos && showOpenedConvo) {
    //   conversations.filter((convo) => {
    //     if (!isConvoFinished(convo) && checkOwner(convo)) convos.push(convo);
    //   });
    //   setFilterConvos(convos);
    // }
    else if (searchValue) {
      handleSearch(searchValue);
    } else {
      if (!convosVisible) setConvosVisible(5);
      setConvoFilter(conversations.slice(0, convosVisible));
    }
  }, [
    conversations,
    convosVisible,
    // showOpenedConvo,
    showMyConvos,
    searchValue,
  ]);

  const handleSearch = (searchVal: string) => {
    const filteredConversations = conversations.filter((convo) => {
      if (convo.friendlyName) {
        return convo.friendlyName
          ?.toLowerCase()
          .includes(searchVal.toLocaleLowerCase());
      } else {
        return convo.sid?.toLowerCase().includes(searchVal);
      }
    });
    setConvoFilter(filteredConversations);
  };

  const handleListClosedConvos = async () => {
    setIsLoading(true);
    const params = {
      query: checkSearchValueType(searchValue),
      limit: limit,
    };
    try {
      const res = await fetchClosedConvos(params);
      successNotification({
        message: "Busca realizada com sucesso",
        addNotifications,
      });
      setIsModalOpen(true);
      setTotalClosedConvos(res.total);
      setClosedConvos(res.items);
    } catch (error) {
      setErrorData(error as any);
      setErrorToShow(ERROR_MODAL_MESSAGES.SEARCH_CONVERSATIONS);
    }
    setIsLoading(false);
  };

  const checkSearchValueType = (searchValue: string) => {
    const regex = /\d/;
    const hasNumber = regex.test(searchValue);
    if (hasNumber) {
      return "number=" + searchValue;
    }
    return "name=" + searchValue;
  };

  const handleRestartConvo = async (convo: any) => {
    setIsReactivating(true);
    try {
      const convoId = await createConversation(
        {
          name: convo.conversation.friendly_name,
          number: convo.participant.messaging_binding.address.replace(
            "whatsapp:+",
            ""
          ),
          partner: localStorage.getItem("partner") as string,
        },
        addNotifications
      );
      setIsReactivating(false);
      setIsModalOpen(false);
      updateCurrentConversation(convoId);
    } catch (response: any) {
      setIsReactivating(false);
      let sid = "";
      if (response?.status === 409) {
        const match = response?.data?.message.match(/Sid:([A-Za-z0-9]+)/);

        if (match) {
          sid = match[1];
          updateCurrentConversation(sid);
          setIsModalOpen(false);
        }
        successNotification({
          message: response?.data?.message,
          addNotifications,
        });
      }
      if (response?.status === 502) {
        setIsModalOpen(false);
        setErrorData(response);
        setErrorToShow(ERROR_MODAL_MESSAGES.ALREADY_EXISTS);
      }
    }
  };

  const handleLoadMoreClosedConvos = async () => {
    if (totalClosedConvos - limit >= 10) setLimit(limit + 10);
    else setLimit(totalClosedConvos);
  };

  useEffect(() => {
    if (limit !== 10) handleListClosedConvos();
  }, [limit]);

  return (
    <div
      id="conversation-list"
      style={{
        minWidth: "300px",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          padding: "14px 16px",
          marginTop: "38px",
          borderBottom: "2px solid #fff",
        }}
      >
        {/* <Checkbox
          id="show-opened-convo"
          checked={showOpenedConvo}
          onChange={() => {
            // setShowMyConvos(false);
            setShowOpenedConvo(!showOpenedConvo);
          }}
        >
          Filtrar conversas em Aberto
        </Checkbox> */}

        <Checkbox
          id="show-my-convos"
          checked={showMyConvos}
          onChange={() => {
            // setShowOpenedConvo(false);
            setShowMyConvos(!showMyConvos);
          }}
        >
          Conversas atribuídas a mim
        </Checkbox>
        <input
          type="text"
          id="search-input"
          autoComplete="false"
          autoSave="false"
          value={searchValue}
          placeholder="Pesquise pelo nome ou número"
          style={{
            border: "1px solid #8891AA",
            padding: "6px 12px",
            height: "32px",
            margin: "0px",
            borderRadius: "4px",
            width: "100%",
          }}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleListClosedConvos();
            }
          }}
        />
        {searchValue && (
          <Button
            fullWidth
            id="search-btn"
            size="small"
            variant="secondary"
            onClick={handleListClosedConvos}
          >
            {isLoading ? "Buscando..." : "Buscar conversas arquivadas"}
          </Button>
        )}
      </div>
      {convoFiltered.map((convo) => (
        <ConversationView
          key={convo.sid}
          convoId={convo.sid}
          setSid={updateCurrentConversation}
          currentConvoSid={sid}
          lastMessage={
            getLastMessage(messages[convo.sid], typingData[convo.sid] ?? []) ??
            ""
          }
          atributedToOthers={hasOtherOwners(convo)}
          // finished={isConvoFinished(convo)}
          messages={messages[convo.sid]}
          typingInfo={typingData[convo.sid] ?? []}
          myMessage={isMyMessage(messages[convo.sid])}
          unreadMessagesCount={setUnreadMessagesCount(
            sid,
            convo.sid,
            unreadMessages,
            updateUnreadMessages,
            convo
          )}
          updateUnreadMessages={updateUnreadMessages}
          participants={participants[convo.sid] ?? []}
          convo={convo}
          onClick={async () => {
            try {
              setLastReadIndex(convo.lastReadMessageIndex ?? -1);
              await updateCurrentConvo(
                updateCurrentConversation,
                convo,
                updateParticipants
              );
              //update unread messages
              updateUnreadMessages(convo.sid, 0);
              //set messages to be read
              const lastMessage =
                messages[convo.sid].length &&
                messages[convo.sid][messages[convo.sid].length - 1];
              if (lastMessage && lastMessage.index !== -1) {
                await getSdkConversationObject(
                  convo
                ).advanceLastReadMessageIndex(lastMessage.index);
              }
            } catch {
              unexpectedErrorNotification(addNotifications);
            }
          }}
        />
      ))}
      <ConvoListModal
        conversationsCount={closedConvos.length}
        convosList={closedConvos}
        handleClose={() => {
          setIsModalOpen(false);
          setLimit(10);
          setSearchValue("");
        }}
        isModalOpen={isModalOpen}
        onRestartConvo={handleRestartConvo}
        searchValue={searchValue}
        title="Lista de conversas arquivadas"
        isSubmitting={isLoading}
        isReactivating={isReactivating}
        onLoadMore={handleLoadMoreClosedConvos}
        totalClosedConvos={totalClosedConvos}
        limit={limit}
      />
      <ActionErrorModal
        errorText={showError || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME}
        isOpened={!!showError}
        onClose={() => {
          setErrorToShow(false);
          setErrorData(undefined);
        }}
        error={errorData}
      />
      {convoFiltered.length ? (
        <div
          style={{
            width: "100%",
            padding: "20px 15px",
            display: "flex",
            flexDirection: "column",
            gap: 10,
            alignItems: "center",
          }}
        >
          <Button
            fullWidth
            variant="primary"
            onClick={showMoreConvos}
            disabled={convosVisible >= conversations.length}
          >
            Mostrar mais
          </Button>
          Mostrando {convosVisible}/
          {showMyConvos ? maxConvosOpen : conversations.length}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            padding: "20px 15px",
            display: "flex",
            flexDirection: "column",
            gap: 10,
            alignItems: "center",
          }}
        >
          Nenhuma conversa...
        </div>
      )}
    </div>
  );
};

export default ConversationsList;
