import { Button } from "@twilio-paste/button";
import { Box, ModalBody } from "@twilio-paste/core";
import { ModalFooter, ModalFooterActions } from "@twilio-paste/modal";
import React, { useState } from "react";
import ModalInputField from "./ModalInputField";

import { ActionName, InputType } from "../../types";
import ConvoModal from "./ConvoModal";
import InputPhone from "./InputPhone";

interface ConversationTitleModalProps {
  title: string;
  setTitle: (title: string) => void;
  number: string;
  setNumber: (number: string) => void;
  proposal: string;
  setProposal: (proposal: string) => void;
  isModalOpen: boolean;
  onCancel: () => void;
  onSave: (title: string, number: string, proposal: string) => Promise<void>;
  type: string;
  isCreatingOrReactivating?: boolean;
}

const ConversationTitleModal: React.FC<ConversationTitleModalProps> = (
  props: ConversationTitleModalProps
) => {
  const [error, setError] = useState("");
  const [isFormDirty, setFormDirty] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [formatPhone, setFormatPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  // useEffect(() => {
  //   if (props.title !== title) {
  //     props.setTitle(props.title);
  //   }
  //   if (props.number !== number) {
  //     setNumber(props.number);
  //   }
  //   if (props.proposal !== proposal) {
  //     setProposal(props.proposal);
  //   }
  // }, [props.title, props.number, props.proposal]);

  const onCancel = () => {
    setError("");
    setFormDirty(false);
    props.setTitle(props.title);
    props.setNumber(props.number);
    props.setProposal(props.proposal);
    props.onCancel();
  };

  const isBadTitle = props.title.length < 1;
  const isBadProposal = () => {
    const partnersFree = ["Lif Saúde"];
    const partner = localStorage.getItem("partner");
    if (partner && partnersFree.includes(partner)) return false;
    return props.proposal.length < 1;
  };

  const checkNumber = (number: string) => {
    // if (formatPhone.length !== number.length) return true;
    return false;
  };

  const onSave = async () => {
    if (props.title.length < 1 || props.number.length < 1) {
      return;
    }
    setLoading(true);
    setError("");

    try {
      await props.onSave(props.title, props.number, props.proposal);
      setLoading(false);
    } catch (e) {
      setError((e as Error).message ?? "");
      setLoading(false);
    }
  };

  const handleNumberChange = (s: string, a: any) => {
    const value = s.replace(/[^\d+]/g, "");
    // const format = a.format.replace(/[^.]/g, "");
    // setFormatPhone(format);
    props.setNumber(value);
  };

  const onSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    if (isBadTitle || isLoading) {
      return;
    }

    setLoading(true);
    onSave();
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (isLoading) {
      return;
    }

    if (e.key === "Escape") {
      e.preventDefault();
      onCancel();
    }
  };
  return (
    <>
      <ConvoModal
        title={props.type == "new" ? "Nova Conversa" : "Editar Conversa"}
        isModalOpen={props.isModalOpen}
        handleClose={onCancel}
        modalBody={
          <ModalBody>
            <Box as="form" onSubmit={onSubmit} onKeyDown={onKeyDown}>
              <ModalInputField
                isFocused={true}
                label="Nome do participante"
                input={props.title}
                placeholder="Digite aqui"
                onChange={(s) => {
                  props.setTitle(s);
                  setFormDirty(s.length === 0);
                }}
                error={
                  error
                    ? error
                    : isFormDirty && !props.title
                    ? "Adicione o nome do participante para criar uma conversa."
                    : ""
                }
              />

              <InputPhone
                label="Celular"
                phone={props.number}
                setPhone={(s, a) => handleNumberChange(s as string, a as any)}
                error={phoneError}
                helpText="O número de celular do participante."
              />
              <ModalInputField
                label="Nº da proposta"
                input={props.proposal}
                placeholder="Digite aqui"
                onChange={(s) => {
                  props.setProposal(s);
                  setFormDirty(s.length === 0);
                }}
                error={
                  error
                    ? error
                    : isFormDirty && !props.proposal
                    ? "Adicione o nº da proposta para criar uma conversa."
                    : ""
                }
              />
            </Box>
          </ModalBody>
        }
        modalFooter={
          <ModalFooter>
            <ModalFooterActions>
              <Button
                disabled={isLoading}
                variant="secondary"
                onClick={onCancel}
              >
                Cancelar
              </Button>
              <Button
                disabled={
                  isBadTitle ||
                  isBadProposal() ||
                  checkNumber(props.number) ||
                  isLoading ||
                  props.isCreatingOrReactivating
                }
                variant="primary"
                onClick={onSave}
              >
                {isLoading || props.isCreatingOrReactivating
                  ? "Salvando..."
                  : ActionName.Save}
              </Button>
            </ModalFooterActions>
          </ModalFooter>
        }
      />
    </>
  );
};

export default ConversationTitleModal;
